<template>
  <div class="modal-overlay">
    <div class="modal-content">
      <div class="block-header">
        <div class="row">
          <div class="col-lg-7 col-md-6 col-sm-12">
            <h4 class="modal-title">Transfer Units</h4>
          </div>
          <div class="col-lg-5 col-md-6 col-sm-12">
            <button
              class="btn btn-primary btn-icon float-right right_icon_toggle_btn"
              type="button"
              @click="$emit('close')"
            >
              <i class="zmdi zmdi-close"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <form @submit.prevent="TransferCredit">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="Unit Amount"
              v-model="amount"
              required
            />
          </div>

          <div class="form-group">
            <select
              class="form-control show-tick"
              v-model="selectedClient"
              v-if="client.length > 0"
              @change="logClientID"
            >
              <option disabled value="">Select Client</option>

              <option
                v-for="sender in client"
                :key="sender.id"
                :value="sender.id"
              >
                {{ sender.id }}
                {{ sender.name }}
              </option>
            </select>
          </div>

          <button class="btn btn-raised btn-primary waves-effect">
            <span v-if="loading">Loading...</span>
            <span v-else>SUBMIT</span>
          </button>
        </form>
      </div>
      <div class="modal-footer">
        <button class="btn btn-danger" @click="$emit('close')">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import SmsService from "../../services/smsService";
import Identity from "../../services/IdentitySms";
import Cookies from "js-cookie";
import { useToast } from "vue-toastification";
export default {
  data() {
    return {
      amount: "",
      client: [],
      selectedClient: "",
      loading: false,
      userId: null,
    };
  },
  methods: {
    logClientID() {
      if (this.selectedClient) {
        console.log("Selected Client:", this.selectedClient);
      }
    },

    async fetchClients() {
      try {
        const token = Cookies.get("auth_token");
        // console.log(token)

        const response = await Identity.post(
          `client/table`,
          {
            page: 1,
            per_page: 1000,
            sort: "client.id|desc",
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );

        // console.log("Clients:", response.data.data);
        if (response.status == 200) {
          this.client = response.data.data;
          //   console.log('fetched clients', this.client)
        }
      } catch (err) {
        console.log(err);
      }
    },

    async TransferCredit() {
      const toast = useToast();

      if (!this.amount || !this.selectedClient) {
        toast.warning("Please fill all fields");
        return;
      }

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        const data = {
          //   client_id: this.userId,
          client_id: this.selectedClient,
          sms_units: parseInt(this.amount, 10),
        };

        console.log("data", data);

        const response = await SmsService.post(
          "dashboard/credit/client",
          data,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("units response", response);
        if (response.status == 200) {
          toast.success(response.data.message);
          window.location.reload();
        }
      } catch (err) {
        console.log(err);
        if (err.status === 422) {
          toast.error(err.response.data.message);
        } else if (err.status === 500) {
          toast.error(err.response.data.message);
        }
      } finally {
        this.loading = false;
      }
    },

    async FetchProfile() {
      try {
        const token = Cookies.get("auth_token");
        this.loading = true;

        const response = await Identity.post(
          "client/balance",
          {},
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log("client details", response.data.message.client);
        if (response.status == 200) {
          this.userId = response.data.message.client.id;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.FetchProfile();
    this.fetchClients();
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  width: 500px;
  border-radius: 10px;
  text-align: center;
}
</style>
