<template>
  <div class="modal-overlay">
    <div class="modal-content">
      <div class="block-header">
        <div class="row">
          <div class="col-lg-7 col-md-6 col-sm-12">
            <h4 class="modal-title">Load Units</h4>
          </div>
          <div class="col-lg-5 col-md-6 col-sm-12">
            <button
              class="btn btn-primary btn-icon float-right right_icon_toggle_btn"
              type="button"
              @click="$emit('close')"
            >
              <i class="zmdi zmdi-close"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <form @submit.prevent="CreateCredit">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="Unit Amount"
              v-model="amount"
              required
            />
          </div>

          <button class="btn btn-raised btn-primary waves-effect">
            <span v-if="loading">Loading...</span>
            <span v-else>SUBMIT</span>
          </button>
        </form>
      </div>
      <div class="modal-footer">
        <button class="btn btn-danger" @click="$emit('close')">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import SmsService from "../../services/smsService";
import Identity from "../../services/IdentitySms";
import Cookies from "js-cookie";
import { useToast } from "vue-toastification";
export default {
  data() {
    return {
      amount: "",

      loading: false,
      userId: null,
    };
  },
  methods: {
    async CreateCredit() {
      const toast = useToast();

      if (!this.amount) {
        toast.warning("Please fill all fields");
        return;
      }

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        const data = {
          client_id: this.userId,
          sms_units: parseInt(this.amount, 10),
        };

        const response = await SmsService.post(
          "dashboard/credit/client",
          data,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("units response", response);
        if (response.status == 200) {
          toast.success(response.data.message);
          window.location.reload();
        }
      } catch (err) {
        console.log(err);
        if (err.status === 422) {
          toast.error(err.response.data.message);
        } else if (err.status === 500) {
          toast.error(err.response.data.message);
        }
      } finally {
        this.loading = false;
      }
    },

    async FetchProfile() {
      try {
        const token = Cookies.get("auth_token");
        this.loading = true;

        const response = await Identity.post(
          "client/balance",
          {},
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log("client details", response.data.message.client);
        if (response.status == 200) {
          this.userId = response.data.message.client.id;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.FetchProfile();
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  width: 500px;
  border-radius: 10px;
  text-align: center;
}
</style>
