<template>
  <div>
    <section class="content">
      <div class="body_scroll">
        <div class="block-header">
          <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
              <h2>Client Sms</h2>
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href=" /"><i class="zmdi zmdi-home"></i> Admin</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="javascript:void(0);">Clients</a>
                </li>
                <li class="breadcrumb-item active">Sms</li>
              </ul>
              <!-- <button
                class="btn btn-primary btn-icon mobile_menu"
                type="button"
              >
                <i class="zmdi zmdi-sort-amount-desc"></i>
              </button> -->
              <button class="btn btn-primary btn-icon mobile_menu-new" type="button" @click="$root.toggleSidebar">
                <!-- @click="$emit('toggle-sidebar')" -->
                <!-- @click ="$root.toggleSidebar" -->
                <i class="zmdi zmdi-sort-amount-desc"></i>
              </button>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
              <button class="btn btn-primary btn-icon float-right right_icon_toggle_btn" type="button">
                <i class="zmdi zmdi-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>

        <div v-if="!selectedClient" class="container-fluid">
          <div class="row clearfix">
            <div class="col-lg-12">
              <div class="card">
                <div class="header">
                  <div class="d-flex justify-content-between">

                    <div>


                      <bu tton type="button" class="btn btn-default waves-effect m-r-20" data-toggle="modal"
                        data-target="#largeModal">
                        CREATE NEW
                      </bu>
                    </div>

                    <div class="col-md-6 col-lg-4 d-flex justify-content-end">
                      <div class="form-group w-100">
                        <label for="searchInput">Search</label>
                        <input type="text" class="form-control" id="searchInput" placeholder="Search..."
                          v-model="searchQuery" />
                      </div>
                    </div>
                  </div>


                  <div class="modal fade" id="largeModal" tabindex="-1" role="dialog">
                    <div class="modal-dialog modal-lg" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h4 class="title" id="largeModalLabel">
                            Create Clients
                          </h4>
                          <hr />
                        </div>
                        <div class="modal-body">
                          <div class="body">
                            <form id="form_validation" @submit.prevent="CreateClient">
                              <div class="form-group form-float">
                                <label class="fw-semibold">Organization name</label>
                                <input type="text" class="form-control" placeholder="Organization name" name="name"
                                  required v-model="organization_name" />
                              </div>

                              <div class="form-group">
                                <div class="radio inlineblock m-r-20">
                                  <input type="text" class="form-control" placeholder="First name" name="first_name"
                                    v-model="firstName" required />
                                </div>
                                <div class="radio inlineblock">
                                  <input type="text" class="form-control" placeholder="Middle Name" name="middlename"
                                    v-model="middleName" />
                                </div>
                              </div>

                              <div class="form-group form-float">
                                <input type="text" class="form-control" placeholder="Last Name" name="lastname"
                                  v-model="lastName" required />
                              </div>

                              <div class="form-group">
                                <div class="radio inlineblock m-r-20">
                                  <input type="email" class="form-control" placeholder="Enter Email Address"
                                    name="email" v-model="email" required />
                                </div>
                                <div class="radio inlineblock">
                                  <input type="text" class="form-control" placeholder="Phone Number" name="msisdn"
                                    v-model="phoneNumber" required />
                                </div>
                              </div>
                              <div class="form-group">
                                <div class="radio inlineblock m-r-20">
                                  <input type="text" class="form-control" placeholder="Building, Street, Apt No."
                                    name="address" v-model="address" required />
                                </div>
                                <div class="radio inlineblock">
                                  <input type="text" class="form-control" placeholder="Enter Town or City"
                                    name="location" v-model="location" required />
                                </div>
                              </div>

                              <div class="form-group form-float">
                                <div class="custom-select" @click="toggleDropdown">
                                  <div class="selected-item">
                                    <img v-if="selectedCountryDetails" :src="selectedCountryDetails.flag" alt="Flag"
                                      width="20" style="margin-right: 10px" />
                                    {{
                                      selectedCountryDetails
                                        ? selectedCountryDetails.name
                                        : "Select Country"
                                    }}
                                  </div>
                                  <ul v-if="dropdownVisible" class="dropdown-list">
                                    <li v-for="country in countries" :key="country.code"
                                      @click="selectCountry(country)">
                                      <img :src="country.flag" alt="Flag" width="20" style="margin-right: 10px" />
                                      {{ country.name }}
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <button class="btn btn-raised btn-primary waves-effect" type="submit">
                                <span v-if="loading">Loading...</span>
                                <span v-else>SUBMIT</span>
                              </button>
                            </form>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-danger waves-effect" data-dismiss="modal">
                            CLOSE
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="body">
                  <div class="table-responsive">
                    <table class="table table-bordered table-striped table-hover js-basic-example dataTable">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Contacts</th>
                          <th>Account Type</th>
                          <th>SMS Balance</th>
                          <th>Status</th>
                          <th>Date</th>
                          <td>Action</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="loading">
                          <td colspan="7">Loading...</td>
                        </tr>
                        <!-- <tr v-else-if=" clients.length === 0 && searchQuery">
                          <td colspan="7">No clients found matching "{{ searchQuery }}"</td>
                        </tr> -->
                        <tr v-else-if="clients.length === 0">
                          <td colspan="7">No clients found</td>
                        </tr>
                        <!-- <tr v-else-if="Array.isArray(clients) && clients.length === 0 && searchQuery">
    <td colspan="7">No clients found matching "{{ searchQuery }}"</td>
  </tr>

  
  <tr v-else-if="Array.isArray(clients) && clients.length === 0">
    <td colspan="7">No clients available</td>
  </tr> -->
                        <tr v-else v-for="(client, index) in paginatedClients" :key="client.id">
                          <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
                          <td>{{ client.name }}</td>
                          <td>
                            {{ client.msisdn }}
                            <small>{{ client.email }}</small>
                          </td>
                          <td>{{ client.account_type }}</td>
                          <td>{{ client.sms_balance }}</td>

                          <td :class="{
                            'active-status': client && client.status == 1,
                            'inactive-status': client && client.status != 1,
                          }">
                            {{
                              client && client.status == 1 ? "Active" : "Muted"
                            }}
                          </td>

                          <td>{{ formatDate(client.created) }}</td>
                          <td>
                            <button class="btn" @click="
                              viewClient(client);
                            fetchBalance(client.id);
                            fetchUsers(client.id);
                            fetchTopups(client.id);
                            fetchConsumption(client.id);
                            fetchNetworkReport(client.id)
                            fetchSenderReport(client.id)
                            fetchSenderIDs(client.id)
                            fetchBills(client.id)
                            fetchClientInfo(client.id)
                              ">
                              View
                            </button>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Contacts</th>
                          <th>Account Type</th>
                          <th>SMS Balance</th>
                          <th>Status</th>
                          <th>Date</th>
                          <td>Action</td>
                        </tr>
                      </tfoot>
                    </table>

                    <nav aria-label="Page navigation">
                      <ul class="pagination">
                        <li class="page-item" :class="{ disabled: currentPage === 1 }">
                          <a class="page-link" href="#" @click.prevent="changePage(1)">First</a>
                        </li>
                        <li class="page-item" :class="{ disabled: currentPage === 1 }">
                          <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">Previous</a>
                        </li>
                        <li v-for="page in limitedPageRange" :key="page" class="page-item"
                          :class="{ active: currentPage === page }">
                          <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
                        </li>
                        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                          <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">Next</a>
                        </li>
                        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                          <a class="page-link" href="#" @click.prevent="changePage(totalPages)">Last</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="container-fluid">
          <button class="btn btn-secondary mb-3" @click="backToTable">
            Back to Table
          </button>
          <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
              <p class="mb-0">
                {{ selectedClient.name }} SMS Balance
                {{ balance && balance ? `${balance}` : "0" }}
              </p>
              <div>
                <button class="btn btn-primary mx-1 btn-sm" data-toggle="modal" data-target="#addcreditModal"
                  @click="handleSenderClick(selectedClient)">Add Credits</button>
                <button class="btn btn-warning mx-1 btn-sm" data-toggle="modal" data-target="#suspendModal"
                  @click="handleSenderClick(selectedClient)">{{ selectedClient && selectedClient.status == 1 ? 'Suspend'
                    : 'Activate' }}</button>
                <button class="btn btn-danger mx-1 btn-sm">Delete</button>
              </div>
            </div>



            <div class="modal fade" id="addcreditModal" tabindex="-1" role="dialog">
              <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h4 class="title" id="addcreditModal">
                      Credit {{ selectedClient.name ? selectedClient.name : '' }}
                    </h4>
                    <hr />
                  </div>
                  <div class="modal-body">
                    <div class="body">
                      <form id="form_validation" @submit.prevent="CreateCredit">
                        <div class="form-group form-float">
                          <label class="fw-semibold">Amount To Credit</label>
                          <input type="text" class="form-control" placeholder="Credit amount eg. 20" required
                            v-model="credit_amount" />
                        </div>



                        <div class="form-group form-float">
                          <label class="fw-semibold">Description</label>
                          <input type="text" class="form-control" placeholder="Why are you doing this?"
                            v-model="description" required />
                        </div>






                        <button class="btn btn-raised btn-primary waves-effect" type="submit">
                          <span v-if="loading">Loading...</span>
                          <span v-else>SUBMIT</span>
                        </button>
                      </form>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-danger waves-effect" data-dismiss="modal">
                      CLOSE
                    </button>
                  </div>
                </div>
              </div>
            </div>



            <div class="modal fade show" id="suspendModal" tabindex="-1" role="dialog">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">
                      {{
                        selectedClient && selectedClient.status === 1
                          ? "Suspend User"
                          : "Activate user"
                      }}
                    </h5>
                  </div>
                  <div class="modal-body">
                    <p>
                      Are you sure you want to
                      {{
                        selectedClient && selectedClient.status === 1
                          ? "Suspend "
                          : "Activate "
                      }}
                      {{ selectedClient ? selectedClient.name : "" }}?
                    </p>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">
                      Close
                    </button>
                    <button type="button" class="btn btn-danger" @click="confirmSus(selectedClient)">
                      <span v-if="loading">Loading...</span><span v-else>YES</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>









            <div class="card-body">
              <ul class="nav nav-tabs contact-group-list">
                <li class="nav-item" v-for="tab in tabs" :key="tab.id">
                  <a :class="['nav-link', { active: activeTab === tab.id }]" href="#"
                    @click.prevent="activeTab = tab.id">
                    {{ tab.label }}
                  </a>
                </li>
              </ul>



              <div class="tab-content mt-4">
                <div v-for="tab in tabs" :key="tab.id" :class="['tab-pane', { 'show active': activeTab === tab.id }]">

                  <div v-if="tab.id === 'reports'">
                    <button class="btn btn-primary mr-2 btn-sm" :class="{ active: reportType === 'network' }"
                      @click="showNetworkReport">
                      Network Report
                    </button>
                    <button class="btn btn-secondary btn-sm" :class="{ active: reportType === 'sender' }"
                      @click="showSenderReport">
                      Sender Report
                    </button>
                  </div>


                  <div v-if="tab.id === 'senderIds'">
                    <button class="btn btn-primary mr-2 btn-sm" data-toggle="modal" data-target="#createSenderModal"
                      @click="handleSenderClick(selectedClient)">
                      Assign Sender
                    </button>

                  </div>



                  <table class="table table-bordered table-striped table-responsive">
                    <thead>
                      <tr>
                        <th v-for="column in tab.columns" :key="column">
                          {{ column }}
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="tab.id === 'users'">
                      <tr v-if="loading">
                        <td colspan="5" class="text-center">Loading...</td>
                      </tr>

                      <tr v-else-if="!userClients.length">
                        <td colspan="5" class="text-center">
                          No data available
                        </td>
                      </tr>

                      <tr v-else v-for="(user, index) in userClients" :key="user.id">
                        <td>{{ index + 1 }}</td>
                        <td>{{ user ? user.full_name : " N/A" }}</td>
                        <td>
                          {{ user ? user.msisdn : "N/A" }}
                          <small>{{ user ? user.email : "N/A" }}</small>
                        </td>
                        <td :class="{
                          'active-status': user && user.user_status == 1,
                          'inactive-status': user && user.user_status != 1,
                        }">
                          {{
                            user && user.user_status == 1 ? "Active" : "Muted"
                          }}
                        </td>
                        <td>
                          <button class="btn btn-sm btn-success" data-toggle="modal" data-target="#addUserClientModal">
                            Add user
                          </button>
                          <button class="btn btn-sm btn-primary" v-if="user && user.user_status == 1"
                            @click="openDeactivateModal(user)" data-toggle="modal" data-target="#deactivateModal">
                            Deactivate
                          </button>
                          <button class="btn btn-sm btn-primary" v-else @click="openDeactivateModal(user)"
                            data-toggle="modal" data-target="#deactivateModal">
                            Activate
                          </button>
                          <button class="btn btn-sm btn-warning" data-toggle="modal" data-target="#resetModal"
                            @click="openDeactivateModal(user)">
                            Reset Password
                          </button>
                          <button class="btn btn-sm btn-danger" @click="openDeleteModal(user)" data-toggle="modal"
                            data-target="#deleteModal">
                            Delete
                          </button>
                        </td>
                      </tr>
                    </tbody>

                    <tbody v-else-if="tab.id === 'smsTopups'">
                      <tr v-if="loading">
                        <td colspan="4" class="text-center">Loading...</td>
                      </tr>

                      <tr v-else-if="!topUps.length">
                        <td colspan="4" class="text-center">
                          No SMS Topups available
                        </td>
                      </tr>

                      <tr v-else v-for="(topup, index) in topUps" :key="topup.id">
                        <td>{{ (page - 1) * perPage + index + 1 }}</td>
                        <td>{{ topup.sms_units || "N/A" }}</td>
                        <td>{{ topup.description || "No description" }}</td>
                        <!-- <td>{{ formatDates(topup.created) }}</td> -->
                         <td>{{topup.created || 'N/A'}}</td>
                      </tr>
                    </tbody>





                    <tbody v-else-if="tab.id === 'smsConsumptions'">
                      <tr v-if="loading">
                        <td colspan="4" class="text-center">Loading...</td>
                      </tr>

                      <tr v-else-if="!consumptions.length">
                        <td colspan="4" class="text-center">
                          No SMS Consumptions available
                        </td>
                      </tr>

                      <tr v-else v-for="(consumption, index) in consumptions" :key="consumption.id">
                        <td>
                          {{
                            (consumption_page - 1) * consumption_per_page +
                            index +
                            1
                          }}
                        </td>
                        <td>{{ consumption.sms_count || "N/A" }}</td>
                        <td>{{ consumption.sender_id || "N/A" }}</td>
                        <!-- <td>{{ formatDates(consumption.date) }}</td> -->
                        <td>{{ consumption.date || "N/A" }}</td>

                      </tr>
                    </tbody>


                    <tbody v-if="tab.id === 'reports'">

                      <thead>
                        <tr>
                          <th v-for="column in (reportType === 'network' ? networkColumns : senderColumns)"
                            :key="column">
                            {{ column }}
                          </th>
                        </tr>
                      </thead>




                      <tr v-if="loading">
                        <td colspan="4" class="text-center">Loading...</td>
                      </tr>

                    <tbody>
                      <!-- Network Report Table -->
                      <tr v-if="reportType === 'network' && loading">
                        <td colspan="4" class="text-center">Loading...</td>
                      </tr>
                      <tr v-else-if="reportType === 'network' && (!networkReports || !networkReports.length)">
                        <td colspan="4" class="text-center">No Network Report data available</td>
                      </tr>
                      <tr v-else-if="reportType === 'network'" v-for="(report) in networkReports || []"
                        :key="report.id">
                        <!-- <td>{{ index + 1 }}</td> -->
                        <td>{{ report?.connector_name || 'N/A' }}</td>
                        <td>{{ report?.sent || 'N/A' }}</td>
                        <td>{{ report?.delivered || 'N/A' }}</td>
                        <td>{{ report?.dlrPercentage || '0.00' }}%</td>
                      </tr>

                      <!-- Sender Report Table -->
                      <tr v-if="reportType === 'sender' && loading">
                        <td colspan="4" class="text-center">Loading...</td>
                      </tr>
                      <tr v-else-if="reportType === 'sender' && (!senderReports || !senderReports.length)">
                        <td colspan="4" class="text-center">No Sender Report data available</td>
                      </tr>
                      <tr v-else-if="reportType === 'sender'" v-for="(report) in senderReports || []" :key="report.id">
                        <!-- <td>{{ index + 1 }}</td> -->
                        <td>{{ report?.sender_id || 'N/A' }}</td>
                        <td>{{ report?.sent || 'N/A' }}</td>
                        <td>{{ report?.delivered || 'N/A' }}</td>
                        <td>{{ report?.dlrPercentage || '0.00' }}%</td>
                      </tr>
                    </tbody>
                    </tbody>


                    <tbody v-else-if="tab.id === 'senderIds'">
                      <tr v-if="loading">
                        <td colspan="5" class="text-center">Loading...</td>
                      </tr>

                      <tr v-else-if="!senderIDs || !senderIDs.length === 0">
                        <td colspan="5" class="text-center">
                          No Sender Ids available
                        </td>
                      </tr>

                      <tr v-else v-for="(senders, index) in senderIDs" :key="senders.id">
                        <td>
                          {{
                            (sender_page - 1) * sender_per_page +
                            index +
                            1
                          }}
                        </td>
                        <td>
                          <div>
                            {{ senders.sender_id || "N/A" }}
                            <small v-if="senders.carrier_name" class="text-muted d-block">
                              {{ senders.carrier_name }}
                            </small>
                          </div>
                        </td>
                        <td :class="{
                          'active-status': senders && senders.otp == 1,
                          'inactive-status': senders && senders.otp != 1,
                        }">
                          {{
                            senders && senders.otp == 1 ? "Yes" : "No"
                          }}
                        </td>
                        <td :class="{
                          'active-status': senders && senders.status == 1,
                          'inactive-status': senders && senders.status != 1,
                        }">
                          {{
                            senders && senders.status == 1 ? "Active" : "Muted"
                          }}
                        </td>
                        <td>
                          <button v-if="senders && senders.otp == 1" class="btn btn-warning btn-sm"
                            @click="openStripModal(senders)" data-toggle="modal" data-target="#stripModal">Disable
                            Message Stripping</button>
                          <button v-else class="btn btn-success btn-sm" @click="openStripModal(senders)"
                            data-toggle="modal" data-target="#stripModal">Enable Message Stripping</button>
                          <button class="btn btn-danger btn-sm" @click="openStripModal(senders)" data-toggle="modal"
                            data-target="#deletestripModal">Delete</button>
                        </td>


                      </tr>
                    </tbody>



                    <tbody v-else-if="tab.id === 'billing'">
                      <tr v-if="loading">
                        <td colspan="3" class="text-center">Loading...</td>
                      </tr>

                      <tr v-else-if="!bills || !bills.length === 0">
                        <td colspan="3" class="text-center">
                          No Bills Data available
                        </td>
                      </tr>

                      <tr v-else v-for="(bill) in bills" :key="bill.connector_id">
                        <!-- <td>
                          {{
        
        index +
        1
      }}
                        </td> -->
                        <td>
                          <div>
                            {{ bill.connector_name || "N/A" }}

                          </div>
                        </td>
                        <td>
                          {{ bill.billing_rate || "N/A" }}
                          KES
                        </td>
                        <td>
                          <button class='btn btn-primary btn-sm' @click="openUpdateModal(bill)" data-toggle="modal"
                            data-target="#updateBillModal">Update</button>
                        </td>



                      </tr>
                    </tbody>







                    <div class="container" v-else-if="tab.id === 'info'">
                      <div class="form-container bg-white">
                        <h4 class="text-dark">Client Information</h4>
                        <!-- @submit.prevent='enableEditing' -->
                        <form>
                          <div class="row mb-3">
                            <div class="col-md-6">
                              <label for="businessName" class="form-label" style="color:black;">Business Name</label>
                              <input type="text" id="businessName" class="form-control" placeholder="Business Name"
                                v-model="businessname">
                            </div>
                            <div class="col-md-6">
                              <label for="accountType" class="form-label" style="color:black;">Account Type</label>
                              <select id="accountType" class="form-control" v-model='selectedAccount'>
                                <option selected value="normal">Normal</option>
                                <option value="reseller">Reseller</option>

                              </select>
                            </div>
                          </div>

                          <div class="row mb-3">
                            <div class="col-md-6">
                              <label for="billingMode" class="form-label" style="color:black;">Billing Mode</label>
                              <select id="billingMode" class="form-control" v-model="selectedBillingMode">
                                <option selected value="PREPAID">PRE-PAY</option>
                                <option value="POSTPAID">POST-PAY</option>
                              </select>
                            </div>
                          </div>

                          <div class="row mb-3">
                            <div class="col-md-6">
                              <label for="email" class="form-label" style="color:black;">Contact Email</label>
                              <input type="email" id="email" class="form-control" placeholder="admin@gmail.com"
                                v-model='businessmail'>
                            </div>
                            <div class="col-md-6">
                              <label for="phone" class="form-label" style="color:black;">Contact Phone Number</label>
                              <input type="text" id="phone" class="form-control" placeholder="2547******"
                                v-model="businessphone">
                            </div>
                          </div>

                          <div class="mb-3">
                            <label for="address" class="form-label" style="color:black;">Address</label>
                            <input type="text" id="address" class="form-control" placeholder="Address"
                              v-model="businessaddress">
                          </div>

                          <div class="text-end">
                            <button type="button" class="btn btn-primary" @click='enableEditing'><span v-if="loading">
                                loading...

                              </span>
                              <span v-else>Edit</span>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>







                    <div v-if="tab.id === 'senderIds'" class="pagination-controls mt-3">
                      <button class="btn btn-primary" :disabled="sender_page === 1" @click="goToPrevioussenderPage">
                        Previous
                      </button>
                      <span>Page {{ sender_page }} of
                        {{ sendertotalPages }}</span>
                      <button class="btn btn-primary" :disabled="sender_page === sendertotalPages"
                        @click="goToNextsenderPage">
                        Next
                      </button>
                    </div>


                    <!-- <nav aria-label="Page navigation" v-if="tab.id === 'senderIds'">
                      <ul class="pagination">
                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === 1 }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(1)"
                            >First</a
                          >
                        </li>
                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === 1 }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(currentPage - 1)"
                            >Previous</a
                          >
                        </li>
                        <li
                          v-for="page in limitedPageRange"
                          :key="page"
                          class="page-item"
                          :class="{ active: currentPage === page }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(page)"
                            >{{ page }}</a
                          >
                        </li>
                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === totalPages }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(currentPage + 1)"
                            >Next</a
                          >
                        </li>
                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === totalPages }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(totalPages)"
                            >Last</a
                          >
                        </li>
                      </ul>
                    </nav> -->






                    <div v-if="tab.id === 'smsTopups'" class="pagination-controls mt-3">
                      <button class="btn btn-primary" :disabled="page === 1" @click="changePages(page - 1)">
                        Previous
                      </button>

                      <span>Page {{ page }} of {{ lastPage }}</span>

                      <button class="btn btn-primary" :disabled="page === lastPage" @click="changePages(page + 1)">
                        Next
                      </button>
                    </div>
                  </table>

                  <div v-if="tab.id === 'smsConsumptions'" class="pagination-controls mt-3">
                    <button class="btn btn-primary" :disabled="consumption_page === 1" @click="goToPreviousPage">
                      Previous
                    </button>
                    <span>Page {{ consumption_page }} of
                      {{ consumptiontotalPages }}</span>
                    <button class="btn btn-primary" :disabled="consumption_page === consumptiontotalPages"
                      @click="goToNextPage">
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>



  <div class="modal fade" id="updateBillModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="title" id="  updateBillModal">
            Update SMS Billing Rate
          </h4>
        </div>
        <div class="modal-body">
          <div class="body">
            <form id="form_validation" v-if="modalBills" @submit.prevent="editBill()">
              <div class="form-group form-float">
                <label class="fw-bold">Previous Billing Rate {{ modalBills ? modalBills.billing_rate : '' }}</label>
                <input type="text" class="form-control" placeholder="Bill Rate eg. 1.00" required v-model="bill_rate" />
              </div>

              <button class="btn btn-raised btn-primary waves-effect" type="submit">
                <span v-if="loading">Loading...</span>
                <span v-else>Update</span>
              </button>
            </form>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger waves-effect" data-dismiss="modal">
            CLOSE
          </button>
        </div>
      </div>
    </div>
  </div>


  <!-- // ADD USER CLIENTS MODAL -->

  <div class="modal fade" id="addUserClientModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="title" id="addUserClientModal">Add User</h4>
          <hr />
        </div>
        <div class="modal-body">
          <div class="body">
            <form id="form_validation" @submit.prevent="CreateClientUser">
              <div class="form-group form-float">
                <label class="fw-semibold">Full Name</label>
                <input type="text" class="form-control" placeholder="Full name" name="name" required
                  v-model="full_name" />
              </div>

              <div class="form-group">
                <div class="radio inlineblock m-r-20">
                  <input type="email" class="form-control" placeholder="Email" name="email" v-model="useremail"
                    required />
                </div>
                <!-- <div class="radio inlineblock">
                  <input type="text" class="form-control" placeholder="Country Dialing Code eg.254" name="dialingCode"
                    v-model="country_code" />
                </div> -->
              </div>

              <div class="form-group form-float">
                <input type="text" class="form-control" placeholder="2547*******" name="phone" v-model="phone"
                  required />
              </div>

              <div class="form-group">
                <select class="form-control" name="userRole" v-model="selectedRole" required>
                  <option disabled value="">User Role Name</option>
                  <option value="1">ROOT</option>
                  <option value="2">Administrator</option>
                  <option value="3">Client Admin</option>
                </select>
              </div>

              <button class="btn btn-raised btn-primary waves-effect" type="submit">
                <span v-if="loading">Loading...</span>
                <span v-else>SUBMIT</span>
              </button>
            </form>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger waves-effect" data-dismiss="modal">
            CLOSE
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- v-if="showDeleteModal" -->
  <div class="modal fade show" id="deactivateModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{
              selectedUser && selectedUser.user_status === 1
                ? "Deactivate User"
                : "Activate user"
            }}
          </h5>
        </div>
        <div class="modal-body">
          <p>
            Are you sure you want to
            {{
              selectedUser && selectedUser.user_status === 1
                ? "Deactivate User"
                : "Activate user"
            }}
            {{ selectedUser ? selectedUser.full_name : "" }}?
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-danger" @click="confirmDeactivate(selectedUser)">
            <span v-if="loading">Loading...</span><span v-else>YES</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade show" id="resetModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <p>
            Are you sure you want to reset Password for
            {{ selectedUser ? selectedUser.full_name : "" }}?
          </p>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-danger" @click="confirmReset(selectedUser)">
            <span v-if="loading">Loading...</span><span v-else>YES</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- v-if="showDeleteModal" -->
  <div class="modal fade show" id="deleteModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirm Delete</h5>
        </div>
        <div class="modal-body">
          <p>
            Are you sure you want to delete
            {{ selectedUser ? selectedUser.full_name : "" }}?
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-danger" @click="deleteClient">
            <span v-if="loading">Loading...</span><span v-else>YES</span>
          </button>
        </div>
      </div>
    </div>
  </div>



  <div class="modal fade show" id="stripModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{
              selectedSender && selectedSender.otp === 1
                ? "Disable Message Stripping"
                : "Enable Message Stripping"
            }}
          </h5>
        </div>
        <div class="modal-body">
          <p>
            Are you sure you want to
            {{
              selectedSender && selectedSender.otp === 1
                ? "Disable Message Stripping"
                : "Enable Message Stripping"
            }}
            {{ selectedSender ? selectedSender.sender_id : "" }}?
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-danger" @click="confirmStrip(selectedSender)">
            <span v-if="loading">Loading...</span><span v-else>YES</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade show" id="deletestripModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirm Delete</h5>
        </div>
        <div class="modal-body">
          <p>
            Are you sure you want to delete
            {{ selectedSender ? selectedSender.sender_id : "" }}?
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-danger" @click="deleteSender">
            <span v-if="loading">Loading...</span><span v-else>YES</span>
          </button>
        </div>
      </div>
    </div>
  </div>





  <div class="modal fade show" id="createSenderModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Assign New Sender ID to {{
              currentModalClient && currentModalClient.name
                ? currentModalClient.name
                : "N/A"
            }}
          </h5>
        </div>
        <div class="modal-body">

          <p class="card-inside-title">Sender Id</p>
          <div class="row clearfix">
            <div class="col-sm-12">
              <select class="form-control show-tick" v-model="selectedSenders" v-if="SenderID.length > 0"
                @change="logSenderID" required>
                <option disabled value="">Select Sender ID</option>

                <option v-for="sender in SenderID" :key="sender.id" :value="sender.id">
                  {{ sender.code }}
                  {{ sender.connector_name }}
                </option>
              </select>
            </div>
          </div>


        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-primary" @click.prevent='assignSender(selectedSender)'>
            <!-- @click="confirmStrip(selectedSender)" -->
            <span v-if="loading">Loading...</span><span v-else>YES</span>
          </button>
        </div>
      </div>
    </div>
  </div>





</template>

<script>
import Cookies from "js-cookie";
import IdentitySmsService from "../services/IdentitySms";
import { format } from "date-fns";
import axios from "axios";
import { useToast } from "vue-toastification";
import SmsService from "../services/smsService";
export default {
  name: "SendSmsPage",

  data() {
    return {
      clients: [],
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      totalItems: 0,
      loading: false,
      selectedCountry: null,
      countries: [],
      dropdownVisible: false,
      selectedCountryDetails: null,
      organization_name: "",
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      address: "",
      location: "",
      selectedClient: null,
      activeTab: "info",
      tabs: [
        {
          id: "info",
          label: "Info",
          // columns: ["Network", "Sent", "Delivered", "% DLR"],
        },
        {
          id: "billing",
          label: "Billing",
          columns: ["Network", "Price Per SMS", "Action"],
        },
        {
          id: "users",
          label: "Users",
          columns: ["#", "Name", "Phone", "Status", "Actions"],
        },
        // {
        //   id: "senderreports",
        //   label: "Sender Reports",
        //   columns: ["Sender ID", "Sent", "Delivered", "% DLR"],
        // },
        {
          id: "reports",
          label: "Reports",
          // columns: ["Network", "Sent", "Delivered", "% DLR"],
        },
        {
          id: "smsTopups",
          label: "SMS Topups",
          columns: ["#", "Topup Amount", "Description", "Date"],
        },
        {
          id: "smsConsumptions",
          label: "SMS Consumptions",
          columns: ["#", "Sender ID", "Number of SMS", "Date"],
        },
        {
          id: "senderIds",
          label: "Sender IDs",
          columns: [
            "#",
            "Sender/ShortCode",
            "Support SMS Stripping",
            "Status",
            "Update",
          ],
        },
        // { id: "ussd", label: "USSD & ShortCode", columns: ["USSD Code", "Type", "Status"] },
        // { id: "premiumService", label: "Premium Service", columns: ["Service ID", "Name", "Status"] },
      ],
      reportType: "network",
      networkColumns: ["Network", "Sent", "Delivered", "%DLR"],
      senderColumns: ["Sender ID", "Sent", "Delivered", "%DLR"],
      balance: "",
      userClients: [],
      full_name: "",
      useremail: "",
      phone: "",
      country_code: "",
      selectedRole: "",
      selectedUser: null,
      page: 1,
      per_page: 10,
      lastPage: 1,
      totalitems: 0,
      topUps: [],
      consumptions: [],
      consumption_page: 1,
      consumption_per_page: 10,
      consumptiontotalPages: 1,
      totalRecords: 0,
      networkReports: [],
      senderReports: [],
      senderIDs: [],
      sender_page: 1,
      sender_per_page: 10,
      sendertotalPages: 1,
      sender_totalRecords: 0,
      selectedSender: null,
      currentModalClient: {
        name: "",
        id: null,
      },
      SenderID: [],
      selectedSenders: "",
      bills: [],
      businessname: "",
      selectedAccount: "",
      selectedBillingMode: "",
      businessmail: "",
      businessphone: "",
      businessaddress: "",
      clientInfo: [],
      infoPage: 1,
      infoPerPage: 10,
      credit_amount: "",
      description: "",
      modalBills: {},
      bill_rate: "",
      searchQuery: "",
    };
  },
  computed: {
    paginatedClients() {
      // const start = (this.currentPage - 1) * this.perPage;
      // const end = start + this.perPage;
      // return this.clients.slice(start, end);
      return this.clients;
    },

    limitedPageRange() {
      // const pages = [];
      // for (let i = 1; i <= this.totalPages; i++) {
      //   pages.push(i);
      // }
      // return pages.slice(0, 10);

      const range = [];
      for (let i = 1; i <= this.totalPages; i++) {
        range.push(i);
      }
      return range.slice(
        Math.max(0, this.currentPage - 5),
        this.currentPage + 5
      );
    },

    //     computedRange() {
    //   const rangeSize = 10;

    //   const adjustedStartPage = Math.max(
    //     1,
    //     Math.min(this.consumption_page - Math.floor(rangeSize / 2), this.consumptiontotalPages - rangeSize + 1)
    //   );
    //   const adjustedEndPage = Math.min(this.consumptiontotalPages, adjustedStartPage + rangeSize - 1);

    //   return Array.from({ length: adjustedEndPage - adjustedStartPage + 1 }, (_, i) => adjustedStartPage + i);
    // }
  },
  mounted() {
    this.fetchClients();
    this.fetchCountries();
    this.fetchBalance();
    // this.fetchUsers();
  },
  methods: {
    logSenderID() {
      if (this.selectedSenders) {
        console.log("Selected Sender:", this.selectedSenders);
      }
    },

    openUpdateModal(bill) {
      this.modalBills = { ...bill };
    },

    handleSenderClick(client) {
      this.currentModalClient = { ...client };
    },

    openDeactivateModal(user) {
      this.selectedUser = user;
    },
    openStripModal(senders) {
      this.selectedSender = senders;
    },
    openDeleteModal(user) {
      this.selectedUser = user;
    },
    viewClient(client) {
      this.selectedClient = client;
    },
    backToTable() {
      this.selectedClient = null;
    },

    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    selectCountry(country) {
      this.selectedCountry = country.code;
      console.log("country code mo", this.selectedCountry);

      this.selectedCountryDetails = country;
      // console.log('country details', this.selectedCountryDetails);
      this.dropdownVisible = false;
    },

    async fetchClients() {
      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        const data = {
          page: this.currentPage,
          per_page: this.perPage,
          sort: "client.id|desc",
          search: this.searchQuery,
        };
        const response = await IdentitySmsService.post(
          // "client/all",
          "client/table",

          data,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("API Response:", response);

        if (response.status == 200) {
          this.clients = response.data.data || [];
          console.log("Assigned clients:", this.clients);
          // this.totalPages = Math.ceil(this.clients.length / this.perPage);
          this.currentPage = response.data.current_page;
          this.totalPages = response.data.last_page;
          this.totalItems = response.data.total;
        } else {
          console.log("Unexpected response status:", response.status);
        }
      } catch (err) {
        console.log("Error fetching clients:", err);
        this.clients = [];
      } finally {
        this.loading = false;
      }
    },

    async CreateClient() {
      const toast = useToast();
      if (
        !this.organization_name ||
        !this.firstName ||
        !this.lastName ||
        !this.email ||
        !this.phoneNumber ||
        !this.address ||
        !this.location ||
        !this.selectedCountry
      ) {
        toast.warning("Please fill in all fields.");
        return;
      }

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");

        const clientData = {
          company_name: this.organization_name,
          full_name: `${this.firstName} ${this.middleName} ${this.lastName}`,
          email: this.email,
          msisdn: this.phoneNumber,
          address: `${this.location} ${this.address}`,
          country_code: this.selectedCountry,
          street: this.address,
          // billing_mode:'PREPAID',
          // billing_rate:1
        };

        const response = await IdentitySmsService.post(
          "client/create",
          clientData,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        console.log("client data creation", response);

        if (response.status == 201) {
          toast.success(response.data.message);
        }
      } catch (err) {
        console.log(err);

        if (err.status == 422) {
          toast.error(err.response.data.message || "Invalid Fields");
        }
      } finally {
        this.loading = false;
      }
    },

    changePage(page) {
      if (page >= 0 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchClients();
      }
    },
    formatDates(date) {
      return format(new Date(date), "do MMM h:mm a");
    },
    formatDate(dateStr) {
      return format(new Date(dateStr), "dd MM yyyy h:mm a");
    },

    async fetchCountries() {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");
        // console.log(response);
        const data = response.data;

        this.countries = data.map((country) => ({
          name: country.name.common,
          code: country.cca2,
          flag: country.flags.png,
        }));

        // console.log("my countries", this.countries);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    },
    async fetchBalance(clientId) {
      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        const response = await SmsService.post(
          "dashboard/client/balance",
          { client_id: clientId },
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("Client Balance:", response);
        if (response.status == 200) {
          this.balance = response.data.message;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async fetchUsers(clientId) {
      // const toast = useToast();

      if (!clientId) {
        // toast.warning("ClientId no selected");
        return;
      }

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        const data = {
          page: 1,
          per_page: 10,
          client_id: clientId,
        };
        const response = await IdentitySmsService.post(
          "client/user/table",
          data,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("My Users:", response.data.data);
        if (response.status == 200) {
          this.userClients = response.data.data;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    async CreateClientUser() {
      const toast = useToast();
      if (
        !this.full_name ||
        !this.useremail ||
        !this.phone ||
        // !this.country_code ||
        !this.selectedRole
      ) {
        toast.warning("Please fill in all fields.");
        return;
      }

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");

        const data = {
          client_id: this.selectedClient.id,
          fullname: this.full_name,
          email: this.useremail,
          msisdn: this.phone,
          // country_code: this.country_code,
          role: this.selectedRole,
          // status: 'ACTIVE',
        };

        console.log("client_user_id", data);

        const response = await IdentitySmsService.post(
          "client/user/create",
          data,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("while creating user", response);
        if (response.status == 201) {
          toast.success(response.data.message);
          this.fetchUsers(this.selectedClient.id);
        }
      } catch (err) {
        console.log(err);
        if (err.status == 500) {
          toast.error(err.response.data.message);
        }
      } finally {
        this.loading = false;
      }
    },
    async confirmDeactivate() {
      if (!this.selectedUser) return;

      const toast = useToast();
      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        const data = {
          status: this.selectedUser.user_status === 1 ? 0 : 1,
          user_id: this.selectedUser.id,
        };

        console.log("update data", data);

        const response = await IdentitySmsService.post(
          "client/user/update",
          data,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        console.log(response);
        if (response.status == 201) {
          toast.success(response.data.message);
          this.fetchUsers(this.selectedClient.id);
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async confirmReset() {
      if (!this.selectedUser) return;

      const toast = useToast();

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        const data = {
          user_id: this.selectedUser.id,
        };

        console.log("reset data", data);

        const response = await IdentitySmsService.post(
          "auth/user/password/reset",
          data,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        console.log(response);
      } catch (err) {
        console.log(err);
        if (err.status == 422) {
          toast.error(err.response.data.message);
        }
      } finally {
        this.loading = false;
      }
    },
    async deleteClient() {
      if (!this.selectedUser) return;

      const toast = useToast();

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        const data = {
          user_id: this.selectedUser.id,
        };

        const response = await IdentitySmsService.post(
          "client/user/delete",
          data,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        console.log(response);
        if (response.status == 201) {
          toast.success(response.data.message);
          this.fetchUsers(this.selectedClient.id);
        }
      } catch (err) {
        console.log(err);
        if (err.status == 422) {
          toast.error(err.response.data.message);
        }
      } finally {
        this.loading = false;
      }
    },
    async fetchTopups(clientId) {
      if (!clientId) return;
      // if (!this.selectedUser) return;

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        const data = {
          page: this.page,
          per_page: this.per_page,
          client_id: clientId,
        };
        console.log("topup data", data);

        const response = await SmsService.post(
          "dashboard/client/balance/history",
          data,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("sms topups", response);
        if (response.status == 200) {
          this.topUps = response.data.data;
          this.page = response.data.current_page;
          this.lastPage = response.data.last_page;
          this.totalItems = response.data.total;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async fetchConsumption(clientId) {
      if (!clientId) return;

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        const data = {
          page: this.consumption_page,
          per_page: this.consumption_per_page,
          user_id: clientId,
        };
        console.log("consumption data", data);

        const response = await SmsService.post(
          "dashboard/sms/transaction/history",
          data,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("sms consumption", response);
        if (response.status == 200) {
          this.consumptions = response.data.data;
          this.consumptiontotalPages = response.data.last_page;
          this.totalRecords = response.data.total;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    async changeConsumptionPage(page) {
      if (page > 0 && page < this.consumptiontotalPages) {
        this.consumption_page = page;
        this.fetchConsumption(this.selectedClient.id);
      }
    },

    goToNextPage() {
      if (this.consumption_page < this.consumptiontotalPages) {
        this.consumption_page++;
        this.fetchConsumption(this.selectedClient.id);
      }
    },
    async goToPreviousPage() {
      if (this.consumption_page > 1) {
        this.consumption_page--;
        this.fetchConsumption(this.selectedClient.id);
      }
    },
    async goToFirstPage() {
      this.changeConsumptionPage(1);
    },
    async goToLastPage() {
      this.changeConsumptionPage(this.consumptiontotalPages);
    },

    changePages(newPage) {
      if (newPage >= 1 && newPage <= this.lastPage) {
        this.page = newPage;
        this.fetchTopups(this.selectedClient.id);
      }
    },

    async fetchNetworkReport(clientId) {
      if (!clientId) return;

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        let end_date = new Date().toISOString().split("T")[0];
        const data = {
          start: "2023-05-18",
          end: end_date,
          id: clientId,
        };
        console.log("Network data", data);

        const response = await SmsService.post(
          // "client/reports/sms/connector",
          "per-client/reports/sms/connector",
          data,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("sms network report", response);
        if (response.status == 200) {
          // this.networkReports = response.data.data;
          // this.consumptiontotalPages = response.data.last_page;
          // this.totalRecords = response.data.total;
          this.networkReports = response.data.message.map((report) => {
            const totalMessages = report.sent + report.failed + report.pending;
            const dlrPercentage = totalMessages
              ? (report.delivered / totalMessages) * 100
              : 0;

            return {
              ...report,
              dlrPercentage: dlrPercentage.toFixed(2),
            };
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async fetchSenderReport(clientId) {
      if (!clientId) return;

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        let end_date = new Date().toISOString().split("T")[0];

        const data = {
          start: "2023-05-18",
          end: end_date,
          id: clientId,
        };

        const response = await SmsService.post(
          "per-client/reports/sms/sender",
          data,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("sender report", response);

        if (response.status == 200) {
          this.senderReports = response.data.message.map((report) => {
            const totalMessages = report.sent + report.failed + report.pending;
            const dlrPercentage = totalMessages
              ? (report.delivered / totalMessages) * 100
              : 0;

            return {
              ...report,
              dlrPercentage: dlrPercentage.toFixed(2),
            };
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async fetchSenderIDs(clientId) {
      if (!clientId) return;

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");

        const data = {
          page: this.sender_page,
          per_page: this.sender_per_page,
          client_id: clientId,
        };

        console.log("sender data", data);

        const response = await SmsService.post(
          "client/senderid/view/table",
          data,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("sender ids mahn", response);

        if (response.status == 200) {
          this.senderIDs = response.data.data;
          this.sendertotalPages = response.data.last_page;
          this.sender_totalRecords = response.data.total;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async fetchBills(clientId) {
      if (!clientId) return;

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");

        const data = {
          id: clientId,
        };

        const response = await IdentitySmsService.post(
          "client/billing/view",
          data,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("bills mahn", response);

        if (response.status == 200) {
          this.bills = response.data.message;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async confirmStrip() {
      if (!this.selectedSender) return;

      const toast = useToast();
      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        const data = {
          status: this.selectedSender.otp === 1 ? 0 : 1,
          id: this.selectedSender.id,
        };

        console.log("strip data", data);

        const response = await SmsService.post(
          "client/senderid/update/otp",
          data,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        console.log(response);
        if (response.status == 202) {
          toast.success(response.data.message);
          this.fetchSenderIDs(this.selectedClient.id);
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async deleteSender() {
      if (!this.selectedSender) return;

      const toast = useToast();
      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        const data = {
          id: this.selectedSender.id,
        };

        console.log("strip data", data);

        const response = await SmsService.post(
          "client/senderid/delete/otp",
          data,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        console.log(response);
        if (response.status == 202) {
          toast.success(response.data.message);
          this.fetchSenderIDs(this.selectedClient.id);
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async fetchSenders() {
      try {
        const token = Cookies.get("auth_token");
        // console.log(token)

        const response = await SmsService.post(
          `configuration/sender/view/list`,
          {},
          {
            headers: {
              Authorization: token,
            },
          }
        );

        console.log("Sender IDs:", response.data.message);
        if (response.data.status == 200) {
          this.SenderID = response.data.message;
        }
      } catch (err) {
        console.log(err);
      }
    },

    async assignSender() {
      // if (!this.selectedSender) return;

      const toast = useToast();
      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        const data = {
          sender_id: this.selectedSenders,
          client_id: this.currentModalClient.id,
        };

        console.log("assign data", data);

        const response = await SmsService.post(
          "admin/client/senderid/create",
          data,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        console.log(response);
        if (response.status == 201) {
          toast.success(response.data.message);
          this.fetchSenderIDs(this.selectedClient.id);
        }
      } catch (err) {
        console.log(err);
        if (err.status == 422) {
          toast.error(err.response.data.message);
        }
      } finally {
        this.loading = false;
      }
    },

    async fetchClientInfo(clientId) {
      if (!clientId) return;

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        const data = {
          page: this.infoPage,
          per_page: this.infoPerPage,
          client_id: clientId,
        };

        console.log("business info", data);

        const response = await IdentitySmsService.post(
          "client/user/table",
          data,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("info", response);

        if (response.status == 200) {
          const clientData = response.data.data[0];
          if (clientData) {
            this.businessname = clientData.full_name || "";
            this.businessmail = clientData.email || "";
            this.businessphone = clientData.msisdn || "";
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async enableEditing() {
      const toast = useToast();

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        console.log(this.selectedClient);
        const data = {
          account_type: this.selectedAccount,
          address: this.businessaddress,
          balance: this.selectedClient.balance,
          billing_mode: this.selectedBillingMode,
          bonus: this.selectedClient.bonus,
          credit_limit: this.selectedClient.credit_limit,
          email: this.businessmail,
          id: this.selectedClient.id,
          msisdn: parseInt(this.businessphone, 10),
          name: this.businessname,
          recipients: this.selectedClient.recipients,
          sms_cost: this.selectedClient.sms_cost,
          status: this.selectedClient.status,
          created: format(
            new Date(this.selectedClient.created),
            "yyyy-MM-dd HH:mm:ss"
          ),
        };

        console.log("edit data", data);

        const response = await IdentitySmsService.post("client/update", data, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        });

        console.log("edit response", response);

        if (response.status == 201) {
          toast.success(response.data.message);
          window.location.reload();
        }
      } catch (err) {
        console.log(err);
        if (err.status == 422) {
          toast.error(err.response.data.message);
        }
      } finally {
        this.loading = false;
      }
    },

    async CreateCredit() {
      const toast = useToast();

      if (!this.credit_amount || !this.description) {
        toast.warning("Please fill all fields");
        return;
      }

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        const data = {
          client_id: this.selectedClient.id,
          description: this.description,
          sms_units: parseInt(this.credit_amount, 10),
        };

        const response = await SmsService.post(
          "dashboard/credit/client",
          data,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("units response", response);
        if (response.status == 200) {
          toast.success(response.data.message);
          window.location.reload();
        }
      } catch (err) {
        console.log(err);
        if (err.status === 422) {
          toast.error(err.response.data.message);
        } else if (err.status === 500) {
          toast.error(err.response.data.message);
        }
      } finally {
        this.loading = false;
      }
    },

    async confirmSus() {
      const toast = useToast();

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        const data = {
          id: this.selectedClient.id,
          status: this.selectedClient.status === 1 ? 0 : 1,
        };

        const response = await IdentitySmsService.post("client/status", data, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        });

        console.log("sus response", response);

        if (response.status == 201) {
          toast.success(response.data.message);
          this.fetchClients();
        }
      } catch (err) {
        console.log(err);
        if (err.status == 422) {
          toast.error(err.response.data.message);
        }
      } finally {
        this.loading = false;
      }
    },

    async editBill() {
      const toast = useToast();
      if (!this.bill_rate) {
        toast.warning("Please fill all fields");
        return;
      }

      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        const data = {
          id: this.selectedClient.id,
          billing_rate: [
            {
              billing_rate: parseInt(this.bill_rate, 10),
              connector_id: this.modalBills.connector_id,
              connector_name: this.modalBills.connector_name,
              mcc: this.modalBills.mcc,
              mnc: this.modalBills.mnc,
            },
          ],
        };

        console.log("billing data", data);

        const response = await IdentitySmsService.post(
          "client/billing/update",
          data,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("bill response", response);
        if (response.status == 201) {
          toast.success(response.data.message);
          this.fetchBills(this.selectedClient.id);
        }
      } catch (err) {
        console.log(err);
        if (err.status == 422) {
          toast.error(err.response.data.message);
        }
      } finally {
        this.loading = false;
      }
    },

    async changeSenderPage(page) {
      if (page >= 1 && page < this.sendertotalPages) {
        this.sender_page = page;
        this.fetchSenderIDs(this.selectedClient.id);
      }
    },

    goToNextsenderPage() {
      if (this.sender_page < this.sendertotalPages) {
        this.sender_page++;
        this.fetchSenderIDs(this.selectedClient.id);
      }
    },
    async goToPrevioussenderPage() {
      if (this.sender_page > 1) {
        this.sender_page--;
        this.fetchSenderIDs(this.selectedClient.id);
      }
    },
    async goToFirstsenderPage() {
      await this.changeSenderPage(1);
    },
    async goToLastsenderPage() {
      await this.changeSenderPage(this.sendertotalPages);
    },

    async showNetworkReport() {
      this.reportType = "network";
      this.fetchNetworkReport(this.selectedClient.id);
    },
    showSenderReport() {
      this.reportType = "sender";
      this.fetchSenderReport(this.selectedClient.id);
    },
  },
  watch: {
    activeTab(newTab) {
      if (newTab === "users" && this.selectedClient && this.selectedClient.id) {
        this.fetchUsers(this.selectedClient.id);
      } else if (
        newTab === "smsTopups" &&
        this.selectedClient &&
        this.selectedClient.id
      ) {
        this.fetchTopups(this.selectedClient.id);
      } else if (
        newTab === "smsConsumptions" &&
        this.selectedClient &&
        this.selectedClient.id
      ) {
        this.fetchConsumption(this.selectedClient.id);
      } else if (
        newTab == "reports" &&
        this.selectedClient &&
        this.selectedClient.id
      ) {
        this.showNetworkReport();
      } else if (
        newTab == "senderIds" &&
        this.selectedClient &&
        this.selectedClient.id
      ) {
        this.fetchSenderIDs(this.selectedClient.id);
        this.fetchSenders();
      } else if (
        newTab == "billing" &&
        this.selectedClient &&
        this.selectedClient.id
      ) {
        this.fetchBills(this.selectedClient.id);
      } else if (
        newTab == "info" &&
        this.selectedClient &&
        this.selectedClient.id
      ) {
        this.fetchClientInfo(this.selectedClient.id);
      }
    },
    searchQuery() {
      this.currentPage = 1;
      this.fetchClients();
    },
  },
};
</script>

<style scoped>
.active-status {
  color: green;
  font-weight: bold;
}

.inactive-status {
  color: red;
  font-weight: bold;
}

.custom-select {
  border: 1px solid #ccc;
  padding: 10px;
  width: 100%;
  position: relative;
  cursor: pointer;
}

.selected-item {
  display: flex;
  align-items: center;
}

.dropdown-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  position: absolute;
  width: 100%;
  background: #fff;
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-list li {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
}

.pagination-controls button {
  padding: 5px 10px;
}

.dropdown-list li:hover {
  background-color: #f0f0f0;
}

.mobile_menu-new {
  display: none;
}

.form-container {
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.form-container h4 {
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.contact-group-list {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 0;
  scrollbar-width: thin;
  scrollbar-color: #ccc #f1f1f1;
}

@media screen and (max-width: 568px) {
  .mobile_menu-new {
    display: block;
    float: right;
  }
}
</style>
