<template>
  <div>
    <section class="content">
      <div class="body_scroll">
        <div class="block-header">
          <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
              <h2>Units Allocation</h2>
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href=" /"><i class="zmdi zmdi-home"></i> Admin</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="javascript:void(0);">Units</a>
                </li>
                <li class="breadcrumb-item active">Allocation</li>
              </ul>
              <!-- <button
                  class="btn btn-primary btn-icon mobile_menu"
                  type="button"
                >
                  <i class="zmdi zmdi-sort-amount-desc"></i>
                </button> -->
              <button
                class="btn btn-primary btn-icon mobile_menu-new"
                type="button"
                @click="$root.toggleSidebar"
              >
                <!-- @click="$emit('toggle-sidebar')" -->
                <!-- @click ="$root.toggleSidebar" -->
                <i class="zmdi zmdi-sort-amount-desc"></i>
              </button>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
              <button
                class="btn btn-primary btn-icon float-right right_icon_toggle_btn"
                type="button"
              >
                <i class="zmdi zmdi-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row clearfix">
            <div class="col-lg-12">
              <div class="card" v-if="userRole === 'Admin'">
                <div class="header">
                  <button
                    type="button"
                    class="btn btn-success waves-effect m-r-20"
                    @click="handleCsvDownload"
                    :disabled="downloadDisabled"
                  >
                    Download
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary waves-effect m-r-20"
                    @click="openModal"
                  >
                    Load Units
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary waves-effect m-r-20"
                    @click="openTransferModal"
                  >
                    Transfer Units
                  </button>
                </div>

                <section class="d-flex flex-wrap justify-content-between mb-4">
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label for="dateRangeSelect">Select Date Range</label>
                      <select
                        class="form-control"
                        id="dateRangeSelect"
                        v-model="selectedOption"
                        @change="handleDateRangeChange"
                      >
                        <option value="choose">Choose Custom</option>
                        <option value="all">All SMS Allocation</option>
                        <option value="today">Today</option>
                        <option value="yesterday">Yesterday</option>
                        <option value="last7">Last 7 Days</option>
                        <option value="lastMonth">Last Month</option>
                        <option value="thisMonth">This Month</option>
                        <option value="custom">Custom Range</option>
                      </select>
                    </div>

                    <div v-if="showCustomRange">
                      <div class="form-group">
                        <label for="startDate">Start Date</label>
                        <input
                          type="date"
                          class="form-control"
                          id="startDate"
                          v-model="startDate"
                          @change="handleCustomDateChange"
                        />
                      </div>
                      <div class="form-group">
                        <label for="endDate">End Date</label>
                        <input
                          type="date"
                          class="form-control"
                          id="endDate"
                          v-model="endDate"
                          @change="handleCustomDateChange"
                        />
                      </div>
                    </div>
                  </div>

                  <!-- <div class="col-md-6 col-lg-4 d-flex justify-content-end">
                      <div class="form-group w-100">
                        <label for="searchInput">Search</label>
                        <input
                          type="text"
                          class="form-control"
                          id="searchInput"
                          placeholder="Search..."
                          v-model="searchQuery"
                        />
                      </div>
                    </div> -->
                </section>

                <div class="body">
                  <div class="table-responsive">
                    <table
                      class="table table-bordered table-striped table-hover"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Event</th>
                          <th>Amount</th>
                          <th>Description</th>
                          <th>Opening Balance</th>
                          <th>Closing Balance</th>
                          <th>Status</th>
                          <th>Date</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-if="loading">
                          <td colspan="8">Loading...</td>
                        </tr>

                        <tr v-else-if="filteredSms.length === 0">
                          <td colspan="8">No SMS Allocation Found</td>
                        </tr>

                        <!-- @click="openModal(sms.message)" -->
                        <tr
                          v-else
                          v-for="(sms, index) in filteredSms"
                          :key="sms.id"
                        >
                          <td>
                            {{
                              (allocationCurrentPage - 1) * perPage + index + 1
                            }}
                          </td>
                          <td>{{ sms.event_title || "N/A" }}</td>
                          <td>{{ sms.amount || "N/A" }}</td>
                          <td>{{ sms.event_description || "N/A" }}</td>
                          <td>{{ sms.opening_balance || "N/A" }}</td>
                          <td>{{ sms.closing_balance || "N/A" }}</td>
                          <td>{{ sms.status || "N/A" }}</td>
                          <td>{{ formatDate(sms.created_at) }}</td>
                        </tr>
                      </tbody>

                      <tfoot>
                        <tr>
                          <th>#</th>
                          <th>Event</th>
                          <th>Amount</th>
                          <th>Description</th>
                          <th>Opening Balance</th>
                          <th>Closing Balance</th>
                          <th>Status</th>
                          <th>Date</th>
                        </tr>
                      </tfoot>
                    </table>

                    <!-- <div v-if="showModal" class="modal-overlay">
                        <div class="modal-content">
                          <h4 style="font-weight: 600">Message</h4>
                          <strong style="font-weight: bold; font-style: italic">{{
                            selectedMessage
                          }}</strong>
                          <button @click="closeModal" class="btn-close-modal">
                            Close
                          </button>
                        </div>
                      </div> -->

                    <!-- Pagination -->
                    <!-- <nav aria-label="Page navigation">
                      <ul class="pagination">
                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === 1 }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(1)"
                            >First</a
                          >
                        </li>

                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === 1 }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(currentPage - 1)"
                            >Previous</a
                          >
                        </li>

                        <li
                          v-for="page in limitedPageRange"
                          :key="page"
                          class="page-item"
                          :class="{ active: currentPage === page }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(page)"
                            >{{ page }}</a
                          >
                        </li>

                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === totalPages }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(currentPage + 1)"
                            >Next</a
                          >
                        </li>

                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === totalPages }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(totalPages)"
                            >Last</a
                          >
                        </li>
                      </ul>
                    </nav> -->
                    <nav aria-label="Allocation Page navigation">
                      <ul class="pagination">
                        <li
                          class="page-item"
                          :class="{ disabled: allocationCurrentPage === 1 }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changeAllocationPage(1)"
                            >First</a
                          >
                        </li>
                        <li
                          class="page-item"
                          :class="{ disabled: allocationCurrentPage === 1 }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="
                              changeAllocationPage(allocationCurrentPage - 1)
                            "
                            >Previous</a
                          >
                        </li>
                        <li
                          v-for="page in allocationTotalPages"
                          :key="page"
                          class="page-item"
                          :class="{ active: allocationCurrentPage === page }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changeAllocationPage(page)"
                            >{{ page }}</a
                          >
                        </li>
                        <li
                          class="page-item"
                          :class="{
                            disabled:
                              allocationCurrentPage === allocationTotalPages,
                          }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="
                              changeAllocationPage(allocationCurrentPage + 1)
                            "
                            >Next</a
                          >
                        </li>
                        <li
                          class="page-item"
                          :class="{
                            disabled:
                              allocationCurrentPage === allocationTotalPages,
                          }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="
                              changeAllocationPage(allocationTotalPages)
                            "
                            >Last</a
                          >
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>

              <section v-else class="card">
                <div class="body">
                  <div class="table-responsive">
                    <table
                      class="table table-bordered table-striped table-hover"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Topup Amount</th>
                          <th>Description</th>
                          <th>Date</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-if="loading">
                          <td colspan="4">Loading...</td>
                        </tr>

                        <tr v-else-if="topUps === null">
                          <td colspan="4">No SMS Allocation Found</td>
                        </tr>

                        <!-- @click="openModal(sms.message)" -->
                        <tr v-else v-for="(sms, index) in topUps" :key="sms.id">
                          <td>
                            {{ (topUpCurrentPage - 1) * perPage + index + 1 }}
                          </td>
                          <td>{{ sms.sms_units || "N/A" }}</td>

                          <td>{{ sms.description || "N/A" }}</td>

                          <td>{{ formatDate(sms.created) }}</td>
                        </tr>
                      </tbody>

                      <tfoot>
                        <tr>
                          <th>#</th>
                          <th>Topup Amount</th>
                          <th>Description</th>
                          <th>Date</th>
                        </tr>
                      </tfoot>
                    </table>

                    <!-- <div v-if="showModal" class="modal-overlay">
                        <div class="modal-content">
                          <h4 style="font-weight: 600">Message</h4>
                          <strong style="font-weight: bold; font-style: italic">{{
                            selectedMessage
                          }}</strong>
                          <button @click="closeModal" class="btn-close-modal">
                            Close
                          </button>
                        </div>
                      </div> -->

                    <!-- Pagination -->
                    <!-- <nav aria-label="Page navigation">
                      <ul class="pagination">
                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === 1 }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(1)"
                            >First</a
                          >
                        </li>

                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === 1 }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(currentPage - 1)"
                            >Previous</a
                          >
                        </li>

                        <li
                          v-for="page in limitedPageRange"
                          :key="page"
                          class="page-item"
                          :class="{ active: currentPage === page }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(page)"
                            >{{ page }}</a
                          >
                        </li>

                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === totalPages }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(currentPage + 1)"
                            >Next</a
                          >
                        </li>

                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === totalPages }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(totalPages)"
                            >Last</a
                          >
                        </li>
                      </ul>
                    </nav> -->
                    <nav aria-label="Top-Up Page navigation">
                      <ul class="pagination">
                        <li
                          class="page-item"
                          :class="{ disabled: topUpCurrentPage === 1 }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changeTopUpPage(1)"
                            >First</a
                          >
                        </li>
                        <li
                          class="page-item"
                          :class="{ disabled: topUpCurrentPage === 1 }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="
                              changeTopUpPage(topUpCurrentPage - 1)
                            "
                            >Previous</a
                          >
                        </li>
                        <li
                          v-for="page in topUpTotalPages"
                          :key="page"
                          class="page-item"
                          :class="{ active: topUpCurrentPage === page }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changeTopUpPage(page)"
                            >{{ page }}</a
                          >
                        </li>
                        <li
                          class="page-item"
                          :class="{
                            disabled: topUpCurrentPage === topUpTotalPages,
                          }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="
                              changeTopUpPage(topUpCurrentPage + 1)
                            "
                            >Next</a
                          >
                        </li>
                        <li
                          class="page-item"
                          :class="{
                            disabled: topUpCurrentPage === topUpTotalPages,
                          }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changeTopUpPage(topUpTotalPages)"
                            >Last</a
                          >
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
    <LoadUnits v-if="showLoadUnits" @close="closeModal" />
    <TransferUnits v-if="showTransferUnits" @close="closeTransferModal" />
  </div>
</template>

<script>
import SMSService from "../services/smsService";
import Cookies from "js-cookie";
import { format, startOfMonth, endOfMonth, subDays, subMonths } from "date-fns";
import LoadUnits from "../components/Modals/LoadUnits.vue";
import TransferUnits from "../components/Modals/TransferUnits.vue";
import IdentitySmsService from "../services/IdentitySms";

export default {
  name: "SmsAllocationPage",
  components: {
    LoadUnits,
    TransferUnits,
  },
  data() {
    return {
      allocationSms: [],
      filteredSms: [],
      loading: false,
      allocationCurrentPage: 1,
      allocationTotalPages: 1,
      topUpCurrentPage: 1,
      topUpTotalPages: 1,
      perPage: 10,
      totalPages: 1,
      showLoadUnits: false,
      showTransferUnits: false,
      // selectedMessage: "",
      selectedOption: "all",
      showCustomRange: false,
      startDate: "",
      endDate: "",
      userId: null,
      userRole: "User",
      topUps: [],
      // searchQuery: "",
    };
  },
  computed: {
    downloadDisabled() {
      return !this.filteredSms || this.filteredSms.length === 0;
    },
    paginatedSms() {
      // if (!Array.isArray(this.filteredSms)) {
      //   return [];
      // }

      // const start = (this.currentPage - 1) * this.perPage;
      // const end = start + this.perPage;
      // return this.transactionalSms.slice(start, end);
      // return this.filteredSms.slice(start, end);
      // return this.filteredSms;

      if (this.userRole === "Admin") {
        return this.filteredSms;
      } else if (this.userRole === "User") {
        return this.topUps;
      }
      return [];
    },
    limitedPageRange() {
      const start = Math.max(1, this.currentPage - 5);
      const end = Math.min(this.totalPages, start + 9);
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },
  },
  methods: {
    handleDateRangeChange() {
      const today = new Date();

      switch (this.selectedOption) {
        case "all": {
          this.startDate = "2024-11-17";
          this.endDate = format(today, "yyyy-MM-dd");
          this.showCustomRange = false;
          break;
        }

        case "today": {
          const formattedToday = format(today, "yyyy-MM-dd");
          this.startDate = formattedToday;
          this.endDate = formattedToday;
          this.showCustomRange = false;
          break;
        }

        case "yesterday": {
          const yesterday = subDays(today, 1);
          const formattedYesterday = format(yesterday, "yyyy-MM-dd");
          this.startDate = formattedYesterday;
          this.endDate = formattedYesterday;
          this.showCustomRange = false;
          break;
        }

        case "last7": {
          const last7Days = subDays(today, 7);
          this.startDate = format(last7Days, "yyyy-MM-dd");
          this.endDate = format(today, "yyyy-MM-dd");
          this.showCustomRange = false;
          break;
        }

        case "lastMonth": {
          const lastMonth = subMonths(today, 1);
          this.startDate = format(startOfMonth(lastMonth), "yyyy-MM-dd");
          this.endDate = format(endOfMonth(lastMonth), "yyyy-MM-dd");
          this.showCustomRange = false;
          break;
        }

        case "thisMonth": {
          this.startDate = format(startOfMonth(today), "yyyy-MM-dd");
          this.endDate = format(today, "yyyy-MM-dd");
          this.showCustomRange = false;
          break;
        }

        case "custom": {
          this.showCustomRange = true;
          break;
        }

        default: {
          this.startDate = "";
          this.endDate = "";
          this.showCustomRange = false;
        }
      }
      console.log("Selected Option:", this.selectedOption);
      console.log("Start Date:", this.startDate);
      console.log("End Date:", this.endDate);
      this.fetchAllocationSms();
    },

    handleCustomDateChange() {
      console.log("Custom Start Date:", this.startDate);
      console.log("Custom End Date:", this.endDate);

      this.fetchAllocationSms();
    },

    openModal() {
      // this.selectedMessage = message;

      this.showLoadUnits = true;
    },

    closeModal() {
      this.showLoadUnits = false;
    },

    openTransferModal() {
      this.showTransferUnits = true;
    },

    closeTransferModal() {
      this.showTransferUnits = false;
    },

    async fetchAllocationSms() {
      try {
        const token = Cookies.get("auth_token");
        this.loading = true;

        const data = {
          page: this.allocationCurrentPage,
          per_page: this.perPage,
          start_date: this.startDate,
          end_date: this.endDate,
        };
        console.log(data);

        const response = await SMSService.post(`activitylogs/all`, data, {
          headers: {
            Authorization: token,
          },
        });
        console.log("allocation sms", response);

        if (response.status === 200) {
          this.allocationSms = response.data.data || [];
          this.filteredSms = this.allocationSms;
          this.allocationTotalPages = response.data.last_page || 1;
        }
      } catch (err) {
        console.log(err);
        this.allocationSms = [];
        this.filteredSms = [];
      } finally {
        this.loading = false;
      }
    },

    async FetchProfile() {
      try {
        const token = Cookies.get("auth_token");
        this.loading = true;
        // const data = {
        //   client_id: this.userId,
        //   sms_units: 1,
        //   description: "",
        // };

        const response = await IdentitySmsService.post(
          "client/balance",
          {},
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log("client details", response.data.message.client);
        if (response.status == 200) {
          // window.location.reload();
          this.userId = response.data.message.client.id;
          console.log("This is the fetched user id", this.userId);
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    //   async fetchTransactionalSearch() {
    //     try {
    //       const token = Cookies.get("auth_token");
    //       this.loading = true;
    //       const data = {
    //         page: this.currentPage,
    //         per_page: this.perPage,
    //         msisdn: this.searchQuery,
    //       };
    //       console.log(data);

    //       const response = await SMSService.post(
    //         `message/all/transactional?msisdn=${this.searchQuery}`,
    //         data,
    //         {
    //           headers: {
    //             Authorization: token,
    //           },
    //         }
    //       );
    //       console.log(response);

    //       if (response.status === 200) {
    //         this.filteredSms = response.data.data || [];
    //         this.totalPages = response.data.last_page || 1;
    //       }
    //     } catch (err) {
    //       console.log(err);
    //       this.filteredSms = [];
    //     } finally {
    //       this.loading = false;
    //     }
    //   },

    //   async handleSearch() {
    //     if (this.searchQuery.trim() === "") {
    //       this.filteredSms = this.allocationSms;
    //     } else {
    //       await this.fetchTransactionalSearch();
    //     }
    //   },

    // async changePage(page) {
    //   if (page >= 1 && page <= this.totalPages) {
    //     this.currentPage = page;
    //     await this.fetchTransactional();
    //   }
    // },

    async fetchTopups() {
      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        const data = {
          page: this.topUpCurrentPage,
          per_page: this.perPage,
          client_id: this.userId,
        };
        console.log("topup data", data);

        const response = await SMSService.post(
          "dashboard/client/balance/history",
          data,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("sms topups", response);
        if (response.status == 200) {
          this.topUps = response.data.data;
          // this.page = response.data.current_page;
          // this.lastPage = response.data.last_page;
          // this.totalItems = response.data.total;
          this.topUpTotalPages = response.data.last_page || 1;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // async changePage(page) {
    //   console.log(page);
    //   if (page >= 1 && page <= this.totalPages) {
    //     this.currentPage = page;

    //     try {
    //       await this.fetchAllocationSms();
    //       // if (this.searchQuery.trim() === "") {
    //       // } else {
    //       //   await this.fetchTransactionalSearch();
    //       // }

    //       this.filteredSms = this.allocationSms;
    //     } catch (error) {
    //       console.error("Error while changing page:", error);
    //       this.filteredSms = [];
    //     }
    //   }
    // },

    async changeAllocationPage(page) {
      if (page >= 1 && page <= this.allocationTotalPages) {
        this.allocationCurrentPage = page;
        await this.fetchAllocationSms();
      }
    },
    async changeTopUpPage(page) {
      if (page >= 1 && page <= this.topUpTotalPages) {
        this.topUpCurrentPage = page;
        await this.fetchTopups();
      }
    },

    // formatDate(dateStr) {
    //   const date = new Date(dateStr);
    //   return format(date, "do MMM yyyy h:mm a");
    // },
    formatDate(dateStr) {
      if (!dateStr) {
        return "Invalid Date";
      }

      const date = new Date(dateStr);

      if (isNaN(date.getTime())) {
        console.error("Invalid date value:", dateStr);
        return "Invalid Date";
      }

      try {
        return format(date, "do MMM yyyy h:mm a");
      } catch (err) {
        console.error("Error formatting date:", err, "Input date:", dateStr);
        return "Invalid Date";
      }
    },

    async handleCsvDownload() {
      if (!this.downloadDisabled) {
        const csvContent = this.convertToCsv(this.filteredSms);
        const blob = new Blob([csvContent], {
          type: "text/csv;charset=utf-8;",
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          `sms_allocation_${this.selectedOption}.csv`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },

    //   async downloadCsv() {
    //     console.log("Download button clicked");
    //     const allData = await this.fetchAllocationForDownload();
    //     console.log("Fetched data for CSVs:", allData);

    //     if (allData && allData.length > 0) {
    //       const csvContent = this.convertToCsv(allData);
    //       console.log("CSV Content:", csvContent);

    //       const blob = new Blob([csvContent], {
    //         type: "text/csv;charset=utf-8;",
    //       });
    //       const url = URL.createObjectURL(blob);

    //       const link = document.createElement("a");
    //       link.setAttribute("href", url);
    //       link.setAttribute("download", "transactional_sms.csv");
    //       link.style.visibility = "hidden";
    //       document.body.appendChild(link);
    //       link.click();
    //       document.body.removeChild(link);
    //     } else {
    //       console.log("No data available for CSV export.");
    //     }
    //   },

    //   async fetchAllocationForDownload() {
    //     try {
    //       const token = Cookies.get("auth_token");
    //       this.loading = true;

    //       const data = {
    //         page: this.currentPage,
    //         per_page: this.perPage,
    //         start_date: this.startDate,
    //         end_date: this.endDate,
    //       };
    //       console.log(data);

    //       const response = await SMSService.post(
    //         `activitylogs/all`,
    //         data,
    //         {
    //           headers: {
    //             Authorization: token,
    //           },
    //         }
    //       );

    //       console.log("Fetched all allocation data for CSV:", response);

    //       if (response.status === 200) {
    //         return response.data.data;
    //       }
    //     } catch (err) {
    //       console.error("Error fetching data:", err);
    //     } finally {
    //       this.loading = false;
    //     }
    //   },

    convertToCsv(data) {
      const headers = [
        "Event",
        "Amount",
        "Description",
        "Opening Balance",
        "Closing Balance",
        "Status",
        "Date",
      ];
      const csvRows = [
        headers.join(","),
        ...data.map((item) =>
          [
            item.event_title || "N/A",
            item.amount || "N/A",
            item.event_description || "N/A",
            item.opening_balance || "N/A",
            item.closing_balance || "N/A",
            item.status || "N/A",
            this.formatDate(item.created_at),
          ].join(",")
        ),
      ];
      return csvRows.join("\n");
    },
  },

  // watch: {
  //   searchQuery() {
  //     this.handleSearch();
  //   },
  // },

  mounted() {
    this.fetchAllocationSms();
    const storedRoleId = localStorage.getItem("user_role");
    if (storedRoleId) {
      this.userRole = Number(storedRoleId) === 1 ? "Admin" : "User";
    }
    this.FetchProfile();
    this.fetchTopups();
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  width: 200px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn-close-modal {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-close-modal:hover {
  background-color: #0056b3;
}

.mobile_menu-new {
  display: none;
}

@media screen and (max-width: 568px) {
  .contact-group-inner {
    width: 100%;
  }
  .mobile_menu-new {
    display: block;
    float: right;
  }
}
</style>
