// import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
import { createRouter, createWebHashHistory } from "vue-router";

import Register from "@/components/RegisterPage.vue";
import Login from "@/components/LoginPage.vue";
import Dashboard from "@/components/DashboardPage.vue";
import SendSms from "@/components/SendSmsPage.vue";
import TransactionalSms from "@/components/TransactionalSmsPage.vue";
import PromotionalSms from "@/components/PromotionalSmsPage.vue";
import DndSms from "@/components/DndSmsPage.vue";
import GroupSms from "@/components/GroupSmsPage.vue";
import BulkSms from "@/components/BulkSmsPage.vue";
import ScheduledSms from "@/components/ScheduledSmsPage.vue";
import Contact from "@/components/ContactPage.vue";
// import Ussd from "@/components/UssdPage.vue";
// import Shortcode from "@/components/ShortcodePage.vue";
// import SubscriptionSms from "@/components/SubscriptionSmsPage.vue";
import Client from "@/components/ClientPage.vue";
// import Report from "@/components/ReportPage.vue";
import TelcoConfig from "@/components/TelcoConfigPage.vue";
import SenderidConfig from "@/components/SenderidConfigPage.vue";
// import UssdConfig from "@/components/UssdConfigPage.vue";
// import ShortcodeConfig from "@/components/ShortcodeConfigPage.vue";
import SystemUser from "@/components/SystemUserPage.vue";
import UserRole from "@/components/UserRolePage.vue";
import SmppAccount from "@/components/SmppAccountPage.vue";
// import Profile from "@/components/ProfilePage.vue";
import Topup from "@/components/TopupPage.vue";
import TopupHistory from "@/components/TopupHistoryPage.vue";
import SmsConsumption from "@/components/SmsConsumptionPage.vue";
import SmsAllocation from "@/components/SmsAllocation.vue";
import SmsReport from "@/components/SmsReportPage.vue";
// import SmsBillingReport from "@/components/SmsBillingReportPage.vue";
// import AdminBillingReport from "@/components/AdminBillingReportPage.vue";
import SenderIdReport from "@/components/SenderIdReportPage.vue";
import SenderIdMapping from "@/components/SenderIdMapping.vue";
import ClientReport from "@/components/ClientReportPage.vue";
import PackageIdReport from "@/components/PackageIdReportPage.vue";
import GlobalSmsBalancePage from "@/components/GlobalSmsBalancePage.vue";
import store from "../store/indexStore";

import NotFound from "@/components/NotFoundPage.vue";

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: Login,
  },
  {
    path: "/register",
    name: "RegisterPage",
    component: Register,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/send-sms",
    name: "SendSms",
    component: SendSms,
    meta: { requiresAuth: true },
  },
  {
    path: "/transactional-sms",
    name: "TransactionalSms",
    component: TransactionalSms,
    meta: { requiresAuth: true },
  },
  {
    path: "/promotional-sms",
    name: "PromotionalSms",
    component: PromotionalSms,
    meta: { requiresAuth: true },
  },
  {
    path: "/group-sms",
    name: "GroupSms",
    component: GroupSms,
    meta: { requiresAuth: true },
  },

  {
    path: "/dnd-sms",
    name: "DndSms",
    component: DndSms,
    meta: { requiresAuth: true },
  },

  {
    path: "/bulk-sms",
    name: "BulkSms",
    component: BulkSms,
    meta: { requiresAuth: true },
  },
  {
    path: "/scheduled-sms",
    name: "ScheduledSms",
    component: ScheduledSms,
    meta: { requiresAuth: true },
  },
  {
    path: "/contact-sms",
    name: "Contact",
    component: Contact,
    meta: { requiresAuth: true },
  },
  {
    // ussd-sms
    path: "/sms-report",
    name: "Ussd",
    component: SmsReport,
    meta: { requiresAuth: true },
  },
  {
    // shortcode-sms
    path: "/sms-report",
    name: "Shortcode",
    component: SmsReport,
    meta: { requiresAuth: true },
  },
  {
    // subscription-sms
    path: "/sms-report",
    name: "SubscriptionSms",
    component: SmsReport,
    meta: { requiresAuth: true },
  },
  {
    path: "/client-sms",
    name: "Client",
    component: Client,
    meta: { requiresAuth: true },
  },
  {
    // report-sms
    path: "/sms-report",
    name: "Report",
    component: SmsReport,
    meta: { requiresAuth: true },
  },
  {
    path: "/telco-configuration",
    name: "TelcoConfig",
    component: TelcoConfig,
    meta: { requiresAuth: true },
  },
  {
    // senderid-configuration
    path: "/senderid-configuration",
    name: "SenderidConfig",
    component: SenderidConfig,
    meta: { requiresAuth: true },
  },
  {
    // ussd-configuration
    path: "/sms-report",
    name: "UssdConfig",
    component: SmsReport,
    meta: { requiresAuth: true },
  },
  {
    // shortcode-configuation
    path: "/sms-report",
    name: "ShortcodeConfig",
    component: SmsReport,
    meta: { requiresAuth: true },
  },
  {
    path: "/system-users",
    name: "SystemUser",
    component: SystemUser,
    meta: { requiresAuth: true },
  },
  {
    path: "/user-roles",
    name: "UserRole",
    component: UserRole,
    meta: { requiresAuth: true },
  },
  {
    path: "/smpp-accounts",
    name: "SmppAccount",
    component: SmppAccount,
    meta: { requiresAuth: true },
  },
  {
    // user-profile
    path: "/sms-report",
    name: "Profile",
    component: SmsReport,
    meta: { requiresAuth: true },
  },
  {
    // account-topup
    path: "/account-topup",
    name: "Topup",
    component: Topup,
    meta: { requiresAuth: true },
  },
  {
    path: "/topup-history",
    name: "TopupHistory",
    component: TopupHistory,
    meta: { requiresAuth: true },
  },
  {
    // sms-consumption
    path: "/sms-consumption",
    name: "SmsConsumption",
    component: SmsConsumption,
    meta: { requiresAuth: true },
  },
  {
    path: "/sms-allocation",
    name: "SmsAllocation",
    component: SmsAllocation,
    meta: { requiresAuth: true },
  },
  {
    path: "/sms-report",
    name: "SmsReport",
    component: SmsReport,
    meta: { requiresAuth: true },
  },
  {
    // billing-report
    path: "/sms-report",
    name: "SmsBillingReport",
    component: SmsReport,
    meta: { requiresAuth: true },
  },
  {
    // admin-billing-report
    path: "/sms-report",
    name: "AdminBillingReport",
    component: SmsReport,
    meta: { requiresAuth: true },
  },
  {
    path: "/senderid-report",
    name: "SenderIdReport",
    component: SenderIdReport,
    meta: { requiresAuth: true },
  },
  {
    path: "/senderid-mapping",
    name: "SenderIdMapping",
    component: SenderIdMapping,
    meta: { requiresAuth: true },
  },
  {
    path: "/client-report",
    name: "ClientReport",
    component: ClientReport,
    meta: { requiresAuth: true },
  },
  {
    path: "/packageid-report",
    name: "PackageIdReport",
    component: PackageIdReport,
    meta: { requiresAuth: true },
  },

  {
    path: "/global-sms",
    name: "GlobalSmsBalancePage",
    component: GlobalSmsBalancePage,
    meta: { requiresAuth: true },
  },

  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    store.dispatch("globalAuth/checkAuth");

    if (!store.getters["globalAuth/isAuthenticated"]) {
      next({ path: "/" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
