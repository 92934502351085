<template>
  <div>
    <section class="content">
      <div class="body_scroll">
        <div class="block-header">
          <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
              <h2>Sms Consumption</h2>
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href=" /"><i class="zmdi zmdi-home"></i> Admin</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="javascript:void(0);">SMS</a>
                </li>
                <li class="breadcrumb-item active">Consumption History</li>
              </ul>
              <button
                class="btn btn-primary btn-icon mobile_menu-new"
                type="button"
                @click="$root.toggleSidebar"
              >
                <i class="zmdi zmdi-sort-amount-desc"></i>
              </button>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
              <button
                class="btn btn-primary btn-icon float-right right_icon_toggle_btn"
                type="button"
              >
                <i class="zmdi zmdi-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>

        <section class="d-flex flex-wrap justify-content-between mb-4">
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="dateRangeSelect">Select Date Range</label>
              <select
                class="form-control"
                id="dateRangeSelect"
                v-model="selectedOption"
                @change="handleDateRangeChange"
              >
                <option value="choose">Choose Custom</option>
                <option value="all">All SMS</option>
                <option value="today">Today</option>
                <option value="yesterday">Yesterday</option>
                <option value="last7">Last 7 Days</option>
                <option value="lastMonth">Last Month</option>
                <option value="thisMonth">This Month</option>
                <option value="custom">Custom Range</option>
              </select>
            </div>

            <div v-if="showCustomRange">
              <div class="form-group">
                <label for="startDate">Start Date</label>
                <input
                  type="date"
                  class="form-control"
                  id="startDate"
                  v-model="startDate"
                  @change="handleCustomDateChange"
                />
              </div>
              <div class="form-group">
                <label for="endDate">End Date</label>
                <input
                  type="date"
                  class="form-control"
                  id="endDate"
                  v-model="endDate"
                  @change="handleCustomDateChange"
                />
              </div>
            </div>
          </div>

          <div
            class="col-md-6 col-lg-4 d-flex justify-content-end"
            v-if="userRole === 'Admin'"
          >
            <div class="form-group w-100">
              <label for="senderID">Select Client</label>
              <select
                class="form-control show-tick"
                v-model="selectedClient"
                v-if="clients.length > 0"
                @change="logClientID"
              >
                <option disabled value="">Select Client</option>

                <option
                  v-for="client in clients"
                  :key="client.id"
                  :value="client.id"
                >
                  {{ client.id }}
                  {{ client.name }}
                </option>
              </select>
            </div>
          </div>

          <!-- <div class="text-center mt-1" style="margin-left: 13px">
            <button
              class="btn btn-primary"
              :disabled="!canFetchData"
              @click="fetchConsumption"
            >
              Fetch Data
            </button>
          </div> -->
          <div class="text-center mt-1" style="margin-left: 13px">
            <button
              class="btn btn-primary"
              :disabled="!canFetchData"
              @click="fetchConsumption"
            >
              <span v-if="!canFetchData">
                <i class="zmdi zmdi-lock-outline"></i> Fetch Data
              </span>
              <span v-else>
                <i class="zmdi zmdi-cloud-download"></i> Fetch Data
              </span>
            </button>
          </div>
        </section>

        <div class="container-fluid">
          <div class="row clearfix">
            <div class="col-lg-12">
              <div class="card">
                <div class="body">
                  <div class="table-responsive">
                    <table
                      class="table table-bordered table-striped table-hover"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Client name</th>
                          <th>SMS count</th>
                          <th>Sender ID</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="loading">
                          <td colspan="5">Loading...</td>
                        </tr>
                        <tr v-else-if="paginatedSms.length === 0">
                          <td colspan="5">No SMS Consumption History Found</td>
                        </tr>
                        <tr
                          v-else
                          v-for="(sms, index) in paginatedSms"
                          :key="sms.id"
                        >
                          <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
                          <td>{{ sms.client_name || "N/A" }}</td>
                          <td>{{ sms.sms_count || "N/A" }}</td>
                          <td>{{ sms.sender_id || "N/A" }}</td>
                          <td>{{ sms.date_range || "N/A" }}</td>
                          <!-- <td>{{ formatDate(sms.date_range, 'from') }} - {{ formatDate(sms.date_range, 'to') }}</td> -->
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>#</th>
                          <th>Client name</th>
                          <th>SMS count</th>
                          <th>Sender ID</th>
                          <th>Date</th>
                        </tr>
                      </tfoot>
                    </table>

                    <!-- Pagination -->
                    <nav aria-label="Page navigation">
                      <ul class="pagination">
                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === 1 }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(1)"
                            >First</a
                          >
                        </li>
                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === 1 }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(currentPage - 1)"
                            >Previous</a
                          >
                        </li>
                        <li
                          v-for="page in limitedPageRange"
                          :key="page"
                          class="page-item"
                          :class="{ active: currentPage === page }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(page)"
                            >{{ page }}</a
                          >
                        </li>
                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === totalPages }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(currentPage + 1)"
                            >Next</a
                          >
                        </li>
                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === totalPages }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(totalPages)"
                            >Last</a
                          >
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SMSService from "../services/smsService";
import IdentitySmsService from "../services/IdentitySms";

import Cookies from "js-cookie";
import { useToast } from "vue-toastification";
import { format, startOfMonth, endOfMonth, subDays, subMonths } from "date-fns";

export default {
  name: "SmsConsumptionPage",
  data() {
    return {
      transactionalSms: [],
      loading: false,
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      selectedOption: "lastMonth",
      showCustomRange: false,
      startDate: "",
      endDate: "",
      clients: [],
      selectedClient: "",
      userId: null,
      userRole: "User",
    };
  },
  computed: {
    paginatedSms() {
      return this.transactionalSms;
    },
    limitedPageRange() {
      const start = Math.max(1, this.currentPage - 5);
      const end = Math.min(this.totalPages, start + 9);
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },
    canFetchData() {
      if (this.userRole === "Admin") {
        return (
          this.startDate &&
          this.endDate &&
          this.selectedClient &&
          this.selectedOption !== "choose"
        );
      } else if (this.userRole === "User") {
        return (
          this.startDate && this.endDate && this.selectedOption !== "choose"
        );
      }

      // Default to false if no role is set
      return false;
    },
  },
  methods: {
    logClientID() {
      if (this.selectedClient) {
        console.log("Selected Client:", this.selectedClient);
      }
    },

    handleDateRangeChange() {
      const today = new Date();

      switch (this.selectedOption) {
        case "all": {
          this.startDate = "2018-02-07";
          this.endDate = format(today, "yyyy-MM-dd");
          this.showCustomRange = false;
          break;
        }

        case "today": {
          const formattedToday = format(today, "yyyy-MM-dd");
          this.startDate = formattedToday;
          this.endDate = formattedToday;
          this.showCustomRange = false;
          break;
        }

        case "yesterday": {
          const yesterday = subDays(today, 1);
          const formattedYesterday = format(yesterday, "yyyy-MM-dd");
          this.startDate = formattedYesterday;
          this.endDate = formattedYesterday;
          this.showCustomRange = false;
          break;
        }

        case "last7": {
          const last7Days = subDays(today, 7);
          this.startDate = format(last7Days, "yyyy-MM-dd");
          this.endDate = format(today, "yyyy-MM-dd");
          this.showCustomRange = false;
          break;
        }

        case "lastMonth": {
          const lastMonth = subMonths(today, 1);
          this.startDate = format(startOfMonth(lastMonth), "yyyy-MM-dd");
          this.endDate = format(endOfMonth(lastMonth), "yyyy-MM-dd");
          this.showCustomRange = false;
          break;
        }

        case "thisMonth": {
          this.startDate = format(startOfMonth(today), "yyyy-MM-dd");
          this.endDate = format(today, "yyyy-MM-dd");
          this.showCustomRange = false;
          break;
        }

        case "custom": {
          this.showCustomRange = true;
          break;
        }

        default: {
          this.startDate = "";
          this.endDate = "";
          this.showCustomRange = false;
        }
      }
      console.log("Selected Option:", this.selectedOption);
      console.log("Start Date:", this.startDate);
      console.log("End Date:", this.endDate);
      // this.fetchConsumption();
    },

    async fetchClients() {
      try {
        this.loading = true;
        const token = Cookies.get("auth_token");
        const data = {
          page: 1,
          per_page: 1000,
          sort: "client.id|desc",
        };
        const response = await IdentitySmsService.post(
          // "client/all",
          "client/table",

          data,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("API Response:", response);

        if (response.status == 200) {
          this.clients = response.data.data || [];
          console.log("Assigned clients:", this.clients);
          // this.totalPages = Math.ceil(this.clients.length / this.perPage);
          // this.currentPage = response.data.current_page;
          // this.totalPages = response.data.last_page;
          // this.totalItems = response.data.total;
        } else {
          console.log("Unexpected response status:", response.status);
        }
      } catch (err) {
        console.log("Error fetching clients:", err);
        this.clients = [];
      } finally {
        this.loading = false;
      }
    },

    // async fetchTransactional() {
    //   try {
    //     const toast = useToast();
    //     const token = Cookies.get("auth_token");
    //     const userId = localStorage.getItem("user_id");
    //     if (!userId) {
    //       toast.info("User ID is missing. Cannot fetch Topup data.");
    //       this.$router.push("/");
    //       return;
    //     }
    //     this.loading = true;

    //     const data = {
    //       sort: "date|desc",
    //       client_id: userId,
    //       page: this.currentPage,
    //       per_page: this.perPage,
    //     };

    //     const response = await SMSService.post(
    //       `dashboard/sms/transaction/history`,
    //       data,
    //       {
    //         headers: {
    //           Authorization: token,
    //         },
    //       }
    //     );
    //     console.log("topup", response);

    //     if (response.status === 200) {
    //       this.transactionalSms = response.data.data || [];
    //       console.log("length", this.transactionalSms.length);
    //       this.totalPages = response.data.last_page || 1;
    //     }
    //   } catch (err) {
    //     console.error("Error fetching transactional data:", err);
    //     this.transactionalSms = [];
    //   } finally {
    //     this.loading = false;
    //   }
    // },
    async fetchConsumption() {
      const toast = useToast();

      try {
        const token = Cookies.get("auth_token");
        this.loading = true;

        const clientId =
          this.userRole === "Admin" ? this.selectedClient : this.userId;

        const payload = {
          page: this.currentPage,
          per_page: this.perPage,
          start_date: this.startDate,
          end_date: this.endDate,
          client_id: clientId,
        };
        // {
        //     page: this.currentPage,
        //     per_page: this.perPage,
        //     // current_page:1,
        //     start_date: this.startDate,
        //     end_date: this.endDate,
        //     client_id: this.clientId,
        //   },

        console.log("sent payload", payload);

        const response = await SMSService.post(
          "dashboard/sms/client/consumption",
          payload,

          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log("consumption", response);
        if (response.status == 200) {
          // this.summary = response.data.message;
          this.transactionalSms = response.data.data || [];
          // console.log("summary data", this.transactionalSms);
          // this.assignChartData();
        }
      } catch (err) {
        console.log(err);
        if (err.status == 422) {
          toast.error(err.response.data.message);
          // setTimeout(()=>{

          //   this.$router.push('/')
          // },1000)
        }
      } finally {
        this.loading = false;
      }
    },

    async FetchProfile() {
      try {
        const token = Cookies.get("auth_token");
        this.loading = true;
        // const data = {
        //   client_id: this.userId,
        //   sms_units: 1,
        //   description: "",
        // };

        const response = await IdentitySmsService.post(
          "client/balance",
          {},
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log("client details", response.data.message.client);
        if (response.status == 200) {
          // window.location.reload();
          this.userId = response.data.message.client.id;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        await this.fetchConsumption();
      }
    },

    // formatDate(dateStr) {
    //   const date = new Date(dateStr);
    //   return format(date, "do MMM yyyy h:mm a");
    // },
    formatDate(dateStr) {
      if (!dateStr) {
        return "Invalid Date";
      }

      const date = new Date(dateStr);

      if (isNaN(date)) {
        return "Invalid Date";
      }

      try {
        return format(date, "do MMM yyyy h:mm a");
      } catch (err) {
        console.error("Error formatting date:", err);
        return "Invalid Date";
      }
    },
  },
  mounted() {
    // this.fetchConsumption();
    this.fetchClients();
    const storedRoleId = localStorage.getItem("user_role");
    if (storedRoleId) {
      this.userRole = Number(storedRoleId) === 1 ? "Admin" : "User";
    }
    this.FetchProfile();
  },
};
</script>

<style scoped>
.mobile_menu-new {
  display: none;
}
@media screen and (max-width: 568px) {
  .mobile_menu-new {
    display: block;
    float: right;
  }
}
.text-success {
  color: green;
}
.text-danger {
  color: red;
}
</style>
