<template>
  <div>
    <div v-if="loading" class="loading-overlay">
      <img
        src="/assets/images/logo.png"
        width="75"
        alt="SMSSavvy"
        class="loading-icon"
      />
    </div>
    <section class="content" v-else>
      <div class="">
        <div class="block-header">
          <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
              <h2>Dashboard</h2>
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/"><i class="zmdi zmdi-home"></i> Admin</a>
                </li>
                <li class="breadcrumb-item active">Dashboard</li>
              </ul>
              <!-- mobile_menu -->
              <button
                class="btn btn-primary btn-icon mobile_menu-new"
                type="button"
                @click="$root.toggleSidebar"
              >
                <!-- @click="$emit('toggle-sidebar')" -->
                <!-- @click ="$root.toggleSidebar" -->
                <i class="zmdi zmdi-sort-amount-desc"></i>
              </button>
              <!-- <i class="zmdi zmdi-sort-amount-desc"></i> -->
              <!-- @click="$root.toggleSidebar" -->
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
              <button
                class="btn btn-primary btn-icon float-right right_icon_toggle_btn"
                type="button"
              >
                <i class="zmdi zmdi-arrow-right"></i>
              </button>

              <!-- <button class="btn-menu ls-toggle-btn" type="button">
          <i class="zmdi zmdi-menu"></i>
        </button> -->
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row clearfix">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="card widget_2">
                <div class="body">
                  <h6>Transactional Messages Sent</h6>
                  <h2>
                    {{
                      summary && summary
                        ? summary.transactional_sent_message
                        : 0
                    }}
                  </h2>

                  <h6>Transactional Contacts</h6>
                  <h2>{{ summary ? summary.transactional_contacts : 0 }}</h2>
                  <small>
                    Transactional Credits
                    {{ summary ? summary.transactional_credits.toFixed(2) : 0 }}
                  </small>
                  <div class="progress">
                    <div
                      class="progress-bar l-amber"
                      role="progressbar"
                      :aria-valuenow="transactionalCreditPercentage"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      :style="{ width: transactionalCreditPercentage + '%' }"
                    ></div>
                    <!-- {{ transactionalCreditPercentage }}% -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="card widget_2">
                <div class="body">
                  <h6>Promotional Messages Sent</h6>
                  <h2>{{ summary ? summary.promotional_sent_message : 0 }}</h2>

                  <h6>Promotional Contacts</h6>
                  <h2>{{ summary ? summary.promotional_contacts : 0 }}</h2>
                  <small>
                    Transactional Credits
                    {{ summary ? summary.promotional_credits.toFixed(2) : 0 }}
                  </small>
                  <div class="progress">
                    <div
                      class="progress-bar l-amber"
                      role="progressbar"
                      :aria-valuenow="promotionalCreditPercentage"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      :style="{ width: promotionalCreditPercentage + '%' }"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row clearfix">
            <div class="col-lg-12">
              <div class="card">
                <div class="header">
                  <h2>
                    <strong><i class="zmdi zmdi-chart"></i> Message</strong>
                    Statistics
                  </h2>
                </div>

                <div class="body">
                  <div class="row clearfix">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                      <!-- Promotional Pie Chart -->
                      <div class="card">
                        <div class="header">
                          <h2>
                            <strong>Promotional Messages Statistics</strong>
                          </h2>
                        </div>
                        <div class="body">
                          <promotional-pie-chart
                            v-if="promotionalData && promotionalData.length > 0"
                            :data="promotionalData"
                          ></promotional-pie-chart>
                          <p v-else>
                            No data available for promotional messages at the
                            moment
                          </p>
                          <div class="legend">
                            <p>
                              <span
                                class="color-box"
                                style="background-color: #ff6384"
                              ></span>
                              Failed
                            </p>
                            <p>
                              <span
                                class="color-box"
                                style="background-color: purple"
                              ></span>
                              Queued
                            </p>
                            <p>
                              <span
                                class="color-box"
                                style="background-color: #36a2eb"
                              ></span>
                              Pending
                            </p>
                            <p>
                              <span
                                class="color-box"
                                style="background-color: #ffce56"
                              ></span>
                              Sent Messages
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-12 col-sm-12">
                      <!-- Transactional Pie Chart -->
                      <div class="card">
                        <div class="header">
                          <h2>
                            <strong>Transactional Messages Statistics</strong>
                          </h2>
                        </div>
                        <div class="body">
                          <transactional-pie-chart
                            v-if="
                              transactionalData && transactionalData.length > 0
                            "
                            :data="transactionalData"
                          ></transactional-pie-chart>
                          <p
                            v-else
                            style="
                              text-align: center;
                              font-weight: bold;
                              color: black;
                            "
                          >
                            No data available for transactional messages at the
                            moment
                          </p>
                          <div class="legend">
                            <p>
                              <span
                                class="color-box"
                                style="background-color: #ff6384"
                              ></span>
                              Failed
                            </p>
                            <p>
                              <span
                                class="color-box"
                                style="background-color: purple"
                              ></span>
                              Queued
                            </p>
                            <p>
                              <span
                                class="color-box"
                                style="background-color: #36a2eb"
                              ></span>
                              Pending
                            </p>
                            <p>
                              <span
                                class="color-box"
                                style="background-color: #ffce56"
                              ></span>
                              Sent Messages
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import SmsService from "../services/smsService";
import PromotionalPieChart from "./charts/promotionalChart";
import TransactionalPieChart from "./charts/transactionalChart";
export default {
  name: "DashboardPage",
  components: {
    PromotionalPieChart,
    TransactionalPieChart,
  },
  data() {
    return {
      summary: null,
      loading: false,
      maxCredits: 1000,
      promotionalMaxCredits: 1000000000,
      promotionalData: null,
      transactionalData: null,
    };
  },
  computed: {
    transactionalCreditPercentage() {
      if (this.summary && this.summary.transactional_credits) {
        return (this.summary.transactional_credits / this.maxCredits) * 100;
      }
      return 0;
    },

    promotionalCreditPercentage() {
      if (this.summary && this.summary.promotional_credits) {
        return (
          (this.summary.promotional_credits / this.promotionalMaxCredits) * 100
        );
      }
      return 0;
    },
  },
  methods: {
    async FetchSummary() {
      try {
        const token = Cookies.get("auth_token");
        this.loading = true;

        const response = await SmsService.post(
          "dashboard/summary",
          {},
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log(response.data.message);
        if (response.status == 200) {
          this.summary = response.data.message;
          // console.log('summary data', this.summary)
          this.assignChartData();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    assignChartData() {
      if (this.summary) {
        this.promotionalData = [
          this.summary.promotional_failed,
          this.summary.promotional_pending,
          this.summary.promotional_queued,
          this.summary.promotional_sent_message,
        ];

        this.transactionalData = [
          this.summary.transactional_failed,
          this.summary.transactional_pending,
          this.summary.transactional_queued,
          this.summary.transactional_sent_message,
        ];
      }
    },
  },
  mounted() {
    if (!localStorage.getItem("dashboardReloaded")) {
      localStorage.setItem("dashboardReloaded", "true");

      window.location.reload();
    } else {
      this.FetchSummary();
    }
  },
};
</script>

<style scoped>
.color-box {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.legend {
  margin-top: 10px;
}

.legend p {
  margin: 5px 0;
}

.mobile_menu-new {
  display: none;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(255, 255, 255, 0.8);  */
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-icon {
  animation: bounce 1.5s ease-in-out infinite;
  transform-origin: center;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@media screen and (max-width: 568px) {
  .mobile_menu-new {
    display: block;
    float: right;
  }
}
</style>
