<template>
  <div>
    <section class="content">
      <div class="body_scroll">
        <div class="block-header">
          <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
              <h2>Sender Id Mapping</h2>
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href=" /"><i class="zmdi zmdi-home"></i> Admin</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="javascript:void(0);">Sender Id</a>
                </li>
                <li class="breadcrumb-item active">Mapping</li>
              </ul>
              <!-- <button
                    class="btn btn-primary btn-icon mobile_menu"
                    type="button"
                  >
                    <i class="zmdi zmdi-sort-amount-desc"></i>
                  </button> -->
              <button
                class="btn btn-primary btn-icon mobile_menu-new"
                type="button"
                @click="$root.toggleSidebar"
              >
                <!-- @click="$emit('toggle-sidebar')" -->
                <!-- @click ="$root.toggleSidebar" -->
                <i class="zmdi zmdi-sort-amount-desc"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row clearfix">
            <div class="col-lg-12">
              <div class="card">
                <!-- <div class="header">
                    <button
                      type="button"
                      class="btn btn-default waves-effect m-r-20"
                      data-toggle="modal"
                      data-target="#largeModal"
                    >
                      Create New
                    </button>
                  </div> -->

                <!-- <div
                    class="modal fade"
                    id="largeModal"
                    tabindex="-1"
                    role="dialog"
                  >
                    <div class="modal-dialog modal-lg" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h4 class="title" id="largeModalLabel">
                            Add Sender IDs Configurations
                          </h4>
                        </div>
                        <div class="modal-body">
                          <div class="body">
                            <form
                              id="form_validation"
                              @submit.prevent="CreateUser"
                            >
                              <div class="form-group form-float">
                                <label class="fw-bold">Sender Name</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Senders name"
                                  name="group"
                                  required
                                  v-model="sendername"
                                />
                              </div>
  
                              <div class="form-group form-float">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="package ID"
                                  name="Email"
                                  required
                                  v-model="packageid"
                                />
                              </div>
  
                              <div class="form-group form-float">
                                <label class="fw-bold">Mobile Network</label>
  
                                <select
                                  class="form-control"
                                  required
                                  @change="handleRoleChange"
                                  v-if="userRoles && userRoles.length > 0"
                                  v-model="selectedRoleId"
                                >
                                  <option disabled value="">
                                    Choose User Role
                                  </option>
                                  <option
                                    v-for="role in userRoles"
                                    :key="role.id"
                                    :value="role.id"
                                  >
                                    {{ role.name }}
                                  </option>
                                </select>
                                <p v-else>No roles available</p>
                              </div>
  
                              <button
                                class="btn btn-raised btn-primary waves-effect"
                                type="submit"
                              >
                                <span v-if="loading">Loading...</span>
                                <span v-else>Add Sender IDs Configurations</span>
                              </button>
                            </form>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-danger waves-effect"
                            data-dismiss="modal"
                          >
                            CLOSE
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> -->

                <!-- <div
                    class="modal fade"
                    id="updateSenderIdModal"
                    tabindex="-1"
                    role="dialog"
                  >
                    <div class="modal-dialog modal-lg" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h4 class="title" id="updateSenderIdModal">
                            Update Sender IDs Configurations
                          </h4>
                        </div>
                        <div class="modal-body">
                          <div class="body">
                            <form
                              id="form_validation"
                              @submit.prevent="UpdateSender"
                            >
                              <div class="form-group form-float">
                                <label class="fw-bold">Sender Name</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Senders name"
                                  name="group"
                                  required
                                  v-model="sendername"
                                />
                              </div>
  
                              <div class="form-group form-float">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="package ID"
                                  name="Email"
                                  required
                                  v-model="packageid"
                                />
                              </div>
  
                              <div class="form-group form-float">
                                <label class="fw-bold">Mobile Network</label>
  
                                <select
                                  class="form-control"
                                  required
                                  @change="handleRoleChange"
                                  v-if="userRoles && userRoles.length > 0"
                                  v-model="selectedRoleId"
                                >
                                  <option disabled value="">
                                    Choose User Role
                                  </option>
                                  <option
                                    v-for="role in userRoles"
                                    :key="role.id"
                                    :value="role.id"
                                  >
                                    {{ role.name }}
                                  </option>
                                </select>
                                <p v-else>No roles available</p>
                              </div>
  
                              <button
                                class="btn btn-raised btn-primary waves-effect"
                                type="submit"
                              >
                                <span v-if="loading">Loading...</span>
                                <span v-else
                                  >Update Sender IDs Configurations</span
                                >
                              </button>
                            </form>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-danger waves-effect"
                            data-dismiss="modal"
                          >
                            CLOSE
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> -->

                <!-- <section class="d-flex flex-wrap justify-content-between mb-4">
                    <div class="col-md-6 col-lg-4 d-flex justify-content-end">
                      <div class="form-group w-100">
                        <label for="searchInput">Search</label>
                        <input
                          type="text"
                          class="form-control"
                          id="searchInput"
                          placeholder="Search..."
                          v-model="searchQuery"
                        />
                      </div>
                    </div>
                  </section> -->

                <!-- <div
                    class="modal fade"
                    id="updateUserRoleModal"
                    tabindex="-1"
                    role="dialog"
                  >
                    <div class="modal-dialog modal-lg" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h4 class="title" id="updateUserRoleModal">
                            {{
                              selectedUser && selectedUser.status === 1
                                ? "Deactivate Contact"
                                : "Activate Contact"
                            }}
  
                            {{
                              selectedUser && selectedUser.code
                                ? ` - ${selectedUser.code}`
                                : ""
                            }}
                          </h4>
                        </div>
                        <div class="modal-body">
                          <div class="body">
                            <p>Are you sure?</p>
  
                            <button
                              class="btn btn-raised btn-primary waves-effect"
                              type="submit"
                              @click="updateUser"
                            >
                              <span v-if="loading">Loading...</span>
                              <span v-else>OK</span>
                            </button>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-danger waves-effect"
                            data-dismiss="modal"
                          >
                            CLOSE
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
   -->
                <!-- <div
                    class="modal fade"
                    id="deleteSenderIdModal"
                    tabindex="-1"
                    role="dialog"
                  >
                    <div class="modal-dialog modal-lg" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h4 class="title" id="deleteSenderIdModal">
                            Delete Sender Id
  
                            {{
                              selectedUser && selectedUser.code
                                ? ` - ${selectedUser.code}`
                                : ""
                            }}
                          </h4>
                        </div>
                        <div class="modal-body">
                          <div class="body">
                            <p>Are you sure?</p>
  
                            <button
                              class="btn btn-raised btn-primary waves-effect"
                              type="submit"
                              @click="deleteSender"
                            >
                              <span v-if="loading">Loading...</span>
                              <span v-else>OK</span>
                            </button>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-danger waves-effect"
                            data-dismiss="modal"
                          >
                            CLOSE
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> -->

                <div class="body">
                  <div class="table-responsive">
                    <table
                      class="table table-bordered table-striped table-hover"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Client Name</th>
                          <th>Sender ID</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-if="loading">
                          <td colspan="7">Loading...</td>
                        </tr>

                        <tr v-else-if="paginatedSms.length === 0">
                          <td colspan="7">No SMS Found</td>
                        </tr>

                        <tr
                          v-else
                          v-for="(sms, index) in paginatedSms"
                          :key="sms.id"
                        >
                          <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
                          <td>{{ sms.client_name || "N/A" }}</td>
                          <td>{{ sms.sender_id || "N/A" }}</td>
                        </tr>
                      </tbody>

                      <tfoot>
                        <tr>
                          <th>#</th>
                          <th>Client Name</th>
                          <th>Sender ID</th>
                        </tr>
                      </tfoot>
                    </table>

                    <div v-if="showModal" class="modal-overlay">
                      <div class="modal-content">
                        <h4 style="font-weight: 600">Message</h4>
                        <strong style="font-weight: bold; font-style: italic">{{
                          selectedMessage
                        }}</strong>
                        <button @click="closeModal" class="btn-close-modal">
                          Close
                        </button>
                      </div>
                    </div>

                    <!-- Pagination -->
                    <nav aria-label="Page navigation">
                      <ul class="pagination">
                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === 1 }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(1)"
                            >First</a
                          >
                        </li>

                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === 1 }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(currentPage - 1)"
                            >Previous</a
                          >
                        </li>

                        <li
                          v-for="page in limitedPageRange"
                          :key="page"
                          class="page-item"
                          :class="{ active: currentPage === page }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(page)"
                            >{{ page }}</a
                          >
                        </li>

                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === totalPages }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(currentPage + 1)"
                            >Next</a
                          >
                        </li>

                        <li
                          class="page-item"
                          :class="{ disabled: currentPage === totalPages }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="changePage(totalPages)"
                            >Last</a
                          >
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SMSService from "../services/smsService";
//   import Ussd from '../services/Ussd'
import Cookies from "js-cookie";
//   import { useToast } from "vue-toastification";
export default {
  name: "SenderIdMapping",
  data() {
    return {
      promotionalSms: [],
      filteredSms: [],
      userRoles: [],
      selectedRoleId: "",
      sendername: "",
      packageid: "",
      loading: false,
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      showModal: false,
      selectedMessage: "",
      selectedOption: "all",
      showCustomRange: false,
      startDate: "",
      endDate: "",
      searchQuery: "",
      selectedUser: null,
    };
  },
  computed: {
    paginatedSms() {
      // if (!Array.isArray(this.filteredSms)) {
      //   return [];
      // }

      // const start = (this.currentPage - 1) * this.perPage;
      // const end = start + this.perPage;
      // return this.promotionalSms.slice(start, end);
      // return this.filteredSms.slice(start, end);
      return this.filteredSms;
    },
    limitedPageRange() {
      const start = Math.max(1, this.currentPage - 5);
      const end = Math.min(this.totalPages, start + 9);
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },
  },
  methods: {
    //   setSelectedUser(user) {
    //     this.selectedUser = { ...user };
    //     this.sendername = user.code || "";
    //     this.packageid = user.package_id || "";
    //   },

    //   handleRoleChange() {
    //     console.log("Selected Role ID:", this.selectedRoleId);
    //   },

    async fetchPromotional() {
      try {
        const token = Cookies.get("auth_token");
        this.loading = true;

        const response = await SMSService.post(
          `configuration/sender/mapping/list`,
          {
            sort: "sender.id|desc",
            page: this.currentPage,
            per_page: this.perPage,
            //   start_date: this.startDate,
            //   end_date: this.endDate,
            // search: query
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log("hey sender id config", response);

        if (response.status === 200) {
          this.promotionalSms = response.data.data || [];
          this.filteredSms = this.promotionalSms;

          this.totalPages = response.data.last_page || 1;
        }
      } catch (err) {
        console.log(err);
        this.promotionalSms = [];
        this.filteredSms = [];
      } finally {
        this.loading = false;
      }
    },

    //   async fetchpromotionalSearch() {
    //     try {
    //       const token = Cookies.get("auth_token");
    //       this.loading = true;
    //       const data = {
    //         page: this.currentPage,
    //         per_page: this.perPage,
    //         search: this.searchQuery,
    //       };
    //       console.log(data);

    //       const response = await SMSService.post(
    //         `configuration/sender/view/table`,
    //         data,
    //         {
    //           headers: {
    //             Authorization: token,
    //           },
    //         }
    //       );
    //       console.log(response);

    //       if (response.status === 200) {
    //         this.filteredSms = response.data.data || [];
    //         this.totalPages = response.data.last_page || 1;
    //       }
    //     } catch (err) {
    //       console.log(err);
    //       this.filteredSms = [];
    //     } finally {
    //       this.loading = false;
    //     }
    //   },

    //   async handleSearch() {
    //     if (this.searchQuery.trim() === "") {
    //       this.filteredSms = this.promotionalSms;
    //     } else {
    //       await this.fetchpromotionalSearch(this.searchQuery.trim());
    //     }
    //   },

    //   async fetchUserRoles() {
    //     const toast = useToast();
    //     try {
    //       const token = Cookies.get("auth_token");
    //       this.loading = true;
    //       const response = await SMSService.post(
    //         "connector/all",
    //         {},

    //         {
    //           headers: {
    //             Authorization: token,
    //           },
    //         }
    //       );
    //       console.log("user roles", response.data.message);
    //       if (response.status === 200) {
    //         this.userRoles = response.data.message || [];
    //       }
    //     } catch (error) {
    //       console.error("Error fetching users:", error);
    //       if (error.status == 401) {
    //         toast.info("Token Expired");
    //         this.$router.push("/");
    //       }
    //       this.userRoles = [];
    //     } finally {
    //       this.loading = false;
    //     }
    //   },

    // async changePage(page) {
    //   if (page >= 1 && page <= this.totalPages) {
    //     this.currentPage = page;
    //     await this.fetchPromotional();
    //   }
    // },

    async changePage(page) {
      console.log(page);
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;

        try {
          if (this.searchQuery.trim() === "") {
            await this.fetchPromotional();
          } else {
            await this.fetchpromotionalSearch();
          }

          this.filteredSms = this.promotionalSms;
        } catch (error) {
          console.error("Error while changing page:", error);
          this.filteredSms = [];
        }
      }
    },

    //   async CreateUser() {
    //     const toast = useToast();

    //     if (!this.sendername || !this.packageid || !this.selectedRoleId) {
    //       toast.warning("Please fill all required fields");
    //       return;
    //     }

    //     try {
    //       const token = Cookies.get("auth_token");
    //       const data = {
    //         code: this.sendername,
    //         package_id: this.packageid,
    //         connector_id: this.selectedRoleId,
    //       };

    //       this.loading = true;

    //       const response = await SMSService.post(
    //         "configuration/sender/create",
    //         data,
    //         {
    //           headers: {
    //             Authorization: token,
    //           },
    //         }
    //       );

    //       console.log(response);
    //       if (response.status == 201) {
    //         toast.success(response.data.message);
    //         this.fetchPromotional();
    //       }
    //     } catch (err) {
    //       console.log(err);
    //     } finally {
    //       this.loading = false;
    //     }
    //   },

    //   async updateUser() {
    //     const toast = useToast();
    //     // console.log("users", this.selectedUser.id);

    //     if (!this.selectedUser || !this.selectedUser.id) {
    //       toast.warning("Sender ID is missing. Cannot update Sender.");
    //       return;
    //     }

    //     const id = this.selectedUser.id;

    //     try {
    //       const token = Cookies.get("auth_token");
    //       this.loading = true;
    //       const data = {
    //         status: this.selectedUser.status === 1 ? 0 : 1,
    //       };

    //       const res = await SMSService.post(
    //         `configuration/sender/${id}/update`,
    //         data,
    //         {
    //           headers: {
    //             Authorization: token,
    //           },
    //         }
    //       );

    //       console.log(res);

    //       if (res.status == 201) {
    //         toast.success(res.data.message);
    //         this.fetchPromotional();
    //       }
    //     } catch (err) {
    //       console.log(err);
    //       if (err.status == 422) {
    //         toast.error(err.response.data.message);
    //       }
    //     } finally {
    //       this.loading = false;
    //     }
    //   },

    //   async UpdateSender() {
    //     const toast = useToast();
    //     if (
    //       !this.selectedUser ||
    //       !this.selectedUser.id ||
    //       !this.sendername ||
    //       !this.packageid ||
    //       !this.selectedRoleId
    //     ) {
    //       toast.warning("Sender ID is missing. Cannot update Sender.");
    //       return;
    //     }

    //     const id = this.selectedUser.id;

    //     try {
    //       const token = Cookies.get("auth_token");
    //       this.loading = true;
    //       const data = {
    //         connector_id: this.selectedRoleId,
    //         package_id: this.packageid,
    //         sender_name: this.sendername,
    //         status: this.selectedUser.status,
    //       };

    //       const res = await SMSService.post(
    //         `configuration/sender/${id}/update`,
    //         data,
    //         {
    //           headers: {
    //             Authorization: token,
    //           },
    //         }
    //       );

    //       console.log(res);
    //       if (res.status == 201) {
    //         toast.success(res.data.message);
    //         this.fetchPromotional();
    //       }
    //     } catch (err) {
    //       console.log(err);
    //       if (err.status == 422) {
    //         toast.error(err.response.data.message);
    //       }
    //     } finally {
    //       this.loading = false;
    //     }
    //   },

    //   async deleteSender() {
    //     const toast = useToast();
    //     if (!this.selectedUser || !this.selectedUser.id) {
    //       toast.warning("Sender ID is missing. Cannot update Sender.");
    //       return;
    //     }

    //     const id = this.selectedUser.id;

    //     try {
    //       const token = Cookies.get("auth_token");
    //       this.loading = true;

    //       const res = await SMSService.post(
    //         `configuration/sender/${id}/delete`,
    //         {},
    //         {
    //           headers: {
    //             Authorization: token,
    //           },
    //         }
    //       );

    //       console.log(res);
    //       if (res.status == 201) {
    //         toast.success(res.data.message);
    //         this.fetchPromotional();
    //       }
    //     } catch (err) {
    //       console.log(err);
    //       if (err.status == 422) {
    //         toast.error(err.response.data.message);
    //       }
    //     } finally {
    //       this.loading = false;
    //     }
    //   },
  },
  // watch: {
  //   searchQuery() {
  //     this.handleSearch();
  //   },
  // },
  mounted() {
    this.fetchPromotional();
    //   this.fetchUserRoles();
  },
};
</script>

<style scoped>
.text-success {
  color: green;
}
.text-danger {
  color: red;
}
.mobile_menu-new {
  display: none;
}

@media screen and (max-width: 568px) {
  .mobile_menu-new {
    display: block;
    float: right;
  }
}
</style>
