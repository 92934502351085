<template>
  <div id="app">
    <!-- @toggle-sidebar="toggleSidebar" -->
    <!-- @click="closeSidebar" v-if="isSidebarOpen" -->
    <div
      class="overlay"
      v-if="isSidebarOpen && isMobile"
      @click="toggleSidebar"
    ></div>
    <!-- @click ='closeSidebar' v-if="isSidebarOpen" -->
    <div
      v-if="route.name !== 'LoginPage' && route.name !== 'RegisterPage'"
      class="navbar-right"
    >
      <!-- style="display: {{    }};" -->
      <ul class="navbar-nav">
        <li>
          <!-- <a href="/" class="mega-menu" title="Sign Out"
            ><i class="zmdi zmdi-power"></i
          ></a> -->
          <i
            class="zmdi zmdi-power"
            style="cursor: pointer"
            title="Sign Out"
            @click="logout"
          ></i>
        </li>
      </ul>
    </div>
    <aside
      v-if="
        // isSidebarOpen&&
        route.name !== 'NotFound' &&
        route.name !== 'LoginPage' &&
        route.name !== 'RegisterPage'
      "
      id="leftsidebar"
      class="sidebar"
      :class="{ open: isSidebarOpen }"
    >
      <!-- :class="{ 'sidebar-open': isSidebarOpen }" -->
      <div class="navbar-brand">
        <button class="btn-menu ls-toggle-btn small-mobile-menu" type="button">
          <i class="zmdi zmdi-menu"></i>
        </button>
        <!-- <a href="/dashboard"><img src="/assets/images/logo.png" width="75" alt="SMSSavvy" /></a> -->
        <router-link to="/dashboard">
          <img src="/assets/images/logo.png" width="75" alt="SMSSavvy" />
        </router-link>
      </div>
      <div class="menu">
        <ul class="list">
          <li>
            <div class="user-info">
              <!-- <a class="image" href="/user-profile"><img src="/assets/images/user-avatar.png" alt="User" /></a> -->
              <!-- user-profile -->
              <router-link to="/dashboard" class="image">
                <img src="/assets/images/user-avatar.png" alt="User" />
              </router-link>
              <div class="detail">
                <h4>{{ userFullName || "User" }}</h4>

                <small>{{ userRole === "Admin" ? "Admin" : "User" }}</small>

                <br />

                <strong>SMS {{ formattedBalance || "0" }}</strong>
              </div>
            </div>
          </li>

          <li
            class="active open btn rounded-pill"
            style="
              background-color: #dc3545;
              display: flex;
              justify-content: center;
            "
            @click="openModal"
          >
            <!-- <a href="#"><i class="zmdi zmdi-mail-send"></i><span>Send SMS</span></a> -->
            <router-link to="#" @click="closeSidebar">
              <i class="zmdi zmdi-mail-send"></i><span>Send SMS</span>
            </router-link>
          </li>

          <li class="active open">
            <!-- <a href="/dashboard"><i class="zmdi zmdi-home"></i><span>Dashboard</span></a> -->

            <router-link to="/dashboard" @click="closeSidebar">
              <i class="zmdi zmdi-home"></i><span>Dashboard</span>
            </router-link>
          </li>

          <li>
            <a href="javascript:void(0);" class="menu-toggle"
              ><i class="zmdi zmdi-email"></i><span>SMS Reports</span></a
            >
            <ul class="ml-menu">
              <!-- <li><a href="/transactional-sms">Transactional SMS (API Report)</a></li> -->
              <li>
                <router-link to="/transactional-sms" @click="closeSidebar"
                  >API Reports</router-link
                >
              </li>
              <!-- <li><a href="/promotional-sms"> ALL messages</a></li> -->
              <li>
                <router-link to="/promotional-sms" @click="closeSidebar"
                  >All Messages</router-link
                >
              </li>
              <!-- <li><a href="/group-sms">Bulk SMS</a></li> -->
              <li>
                <router-link to="/group-sms" @click="closeSidebar"
                  >Bulk SMS</router-link
                >
              </li>

              <!-- <li><a href="/scheduled-sms">Scheduled SMS</a></li> -->

              <li>
                <router-link to="/scheduled-sms" @click="closeSidebar"
                  >Scheduled SMS</router-link
                >
              </li>
              <!-- <li><a href="/dnd-sms">DND</a></li> -->
              <li>
                <router-link to="/dnd-sms" @click="closeSidebar"
                  >DND</router-link
                >
              </li>
            </ul>
          </li>

          <li class="">
            <!-- <a href="/contact-sms"><i class="zmdi zmdi-phone-msg"></i><span>Contact</span></a> -->
            <router-link to="/contact-sms">
              <i class="zmdi zmdi-phone-msg"></i><span>Contact</span>
            </router-link>
          </li>

          <!-- ussd-sms -->
          <!-- <li>
            <a href="javascript:void(0);" class="menu-toggle"
              ><i class="zmdi zmdi-comment-outline"></i
              ><span>USSD & Shortcode</span></a
            >
            <ul class="ml-menu">
              <li><router-link to="/sms-report">USSD</router-link></li>
              <li><router-link to="/sms-report">Shortcode</router-link></li>
            </ul>
          </li> -->
          <!-- shortcode-sms -->

          <li class="" v-if="userRole === 'Admin'">
            <!-- <a href="/client-sms"><i class="zmdi zmdi-accounts-outline"></i><span>Client</span></a> -->
            <router-link to="/client-sms" @click="closeSidebar">
              <i class="zmdi zmdi-accounts-outline"></i><span>Client</span>
            </router-link>
          </li>

          <!-- <a href="/report-sms"><i class="zmdi zmdi-chart"></i><span>Reports</span></a> -->
          <!-- <li class="" v-if="userRole === 'Admin'">
            <router-link to="/report-sms">
              <i class="zmdi zmdi-chart"></i><span>Reports</span>
            </router-link>
          </li> -->

          <li v-if="userRole === 'Admin'">
            <a href="javascript:void(0);" class="menu-toggle"
              ><i class="zmdi zmdi-settings"></i><span>Configurations</span></a
            >
            <ul class="ml-menu">
              <li>
                <router-link to="/telco-configuration" @click="closeSidebar"
                  >Telco configurations</router-link
                >
              </li>
              <li>
                <router-link to="/senderid-configuration" @click="closeSidebar"
                  >Sender ID configuration</router-link
                >
              </li>
              <li v-if="userRole === 'Admin'">
                <router-link to="/senderid-mapping" @click="closeSidebar"
                  >Sender ID Mapping</router-link
                >
              </li>
              <!-- ussd-configuration -->
              <!-- <li>
                <router-link to="/sms-report">USSD configuration</router-link>
              </li> -->
              <!-- <li>
                <router-link to="/sms-report"
                >Shortcode configuration</router-link
                >
              </li> -->
              <!-- shortcode-configuration -->
            </ul>
          </li>

          <li>
            <a href="javascript:void(0);" class="menu-toggle"
              ><i class="zmdi zmdi-account-o"></i
              ><span>User management</span></a
            >
            <ul class="ml-menu">
              <li>
                <router-link to="/system-users" @click="closeSidebar"
                  >System User</router-link
                >
              </li>

              <li v-if="userRole === 'Admin'">
                <router-link to="/user-roles" @click="closeSidebar"
                  >Roles</router-link
                >
              </li>
              <!-- <li>
                <router-link to="/smpp-accounts">SMPP Accounts</router-link>
              </li> -->
            </ul>
          </li>

          <li>
            <a href="javascript:void(0);" class="menu-toggle"
              ><i class="zmdi zmdi-assignment-account"></i
              ><span>Accnt management</span></a
            >
            <ul class="ml-menu">
              <li v-if="userRole === 'Admin'">
                <router-link to="/account-topup" @click="closeSidebar"
                  >Account Top Up</router-link
                >
              </li>
              <li v-if="userRole === 'Admin'">
                <router-link to="/topup-history" @click="closeSidebar"
                  >Top Up History</router-link
                >
              </li>
              <li>
                <router-link to="/sms-consumption" @click="closeSidebar"
                  >SMS Consumption</router-link
                >
              </li>
              <li >
                <router-link to="/sms-allocation" @click="closeSidebar"
                  >Units Allocation</router-link
                >
              </li>
              <li v-if="userRole === 'Admin'">
                <router-link to="/sms-report" @click="closeSidebar"
                  >Network Report</router-link
                >
              </li>
              <!-- billing-report -->
              <!-- <li>
                <router-link to="/sms-report">User Billing Report</router-link>
              </li> -->
              <!-- <li>
                <router-link to="/sms-report">Admin Billing Report</router-link>
              </li> -->
              <!-- admin-billing-report -->
              <!-- <li v-if="userRole === 'Admin'">
                <router-link to="/senderid-report" @click="closeSidebar"
                  >Sender ID Report</router-link
                >
              </li> -->
              <li v-if="userRole === 'Admin'">
                <router-link to="/client-report" @click="closeSidebar"
                  >Client Report</router-link
                >
              </li>
              <li v-if="userRole === 'Admin'">
                <router-link to="/packageid-report" @click="closeSidebar"
                  >Package ID Report</router-link
                >
              </li>
              <li  v-if="userRole === 'Admin'">
                <router-link to="/global-sms" @click="closeSidebar"
                  >Global SMS</router-link
                >
              </li>
            </ul>
          </li>

          <li class="active open">
            <a @click="logout"
              ><i class="zmdi zmdi-power"></i><span>Logout</span></a
            >
          </li>
        </ul>
      </div>
    </aside>

    <!-- Removed: hrefs and replaced with router-links as above -->

    <SendSmsPage v-if="showModal" @close="closeModal" />
    <router-view></router-view>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import SendSmsPage from "./components/SendSmsPage.vue";
import { mapActions } from "vuex";
import { useToast } from "vue-toastification";
import Cookies from "js-cookie";
import SmsService from "./services/smsService";
import Identity from "./services/IdentitySms";
export default {
  name: "App",
  components: {
    SendSmsPage,
  },
  setup() {
    const route = useRoute();
    return { route };
  },
  data() {
    return {
      loading: false,
      showModal: false,
      userRole: "User",
      userFullName: "",
      userId: null,
      balance: null,
      isSidebarOpen: false,
      isMobile: false,
    };
  },

  computed: {
    formattedBalance() {
      if (
        this.balance !== null &&
        this.balance !== undefined &&
        !isNaN(this.balance)
      ) {
        return this.balance.toLocaleString("en-US");
      }

      return "0";
    },
  },

  methods: {
    // openSidebar() {
    //   this.isSidebarOpen = true;
    // },
    // closeSidebar() {
    //   this.isSidebarOpen = false;
    // },
    // toggleSidebar() {
    //   this.isSidebarOpen = !this.isSidebarOpen;
    // },

    toggleSidebar() {
      // Check if window is defined before using window.innerWidth
      if (typeof window !== "undefined" && this.isMobile) {
        this.isSidebarOpen = !this.isSidebarOpen;
      }
    },

    checkIfMobile() {
      // Check if the current screen is a mobile device (<= 568px)
      if (typeof window !== "undefined") {
        this.isMobile = window.innerWidth <= 568;
      }
    },

    closeSidebar() {
      this.isSidebarOpen = false;
    },

    openModal() {
      this.showModal = true;
      this.closeSidebar();
    },
    closeModal() {
      this.showModal = false;
    },
    ...mapActions("login", ["LogoutUser"]),
    logout() {
      const toast = useToast();
      this.LogoutUser();
      toast.info("Logged Out Successfully!");
      setTimeout(() => {
        this.$router.push("/");
      }, 1000);
    },

    async Fetchbalance() {
      try {
        const token = Cookies.get("auth_token");
        this.loading = true;
        const data = {
          client_id: this.userId,
          sms_units: 1,
          description: "",
        };

        const response = await SmsService.post(
          "dashboard/client/balance",
          data,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log("yoww", response.data.message);
        if (response.status == 200) {
          this.balance = response.data.message;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async FetchProfile() {
      try {
        const token = Cookies.get("auth_token");
        this.loading = true;
        // const data = {
        //   client_id: this.userId,
        //   sms_units: 1,
        //   description: "",
        // };

        const response = await Identity.post(
          "client/balance",
          {},
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log("client details", response.data.message.client);
        if (response.status == 200) {
          // window.location.reload();
          this.userId = response.data.message.client.id;
          this.userFullName = response.data.message.client.name;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    // window.location.reload();
    this.FetchProfile();
    this.Fetchbalance();
    // const storedUserFullName = localStorage.getItem("user_full_name");
    // const storedUserId = localStorage.getItem("user_id");
    const storedRoleId = localStorage.getItem("user_role");
    // if (storedUserFullName) {
    //   this.userFullName = storedUserFullName;
    // }

    // if (storedUserId) {
    //   this.userId = Number(storedUserId);
    //   console.log("client user id", this.userId);
    //   this.Fetchbalance();
    // }

    if (storedRoleId) {
      this.userRole = Number(storedRoleId) === 1 ? "Admin" : "User";
    }
    this.checkIfMobile();
    window.addEventListener("resize", this.checkIfMobile);
  },
  beforeUnmount() {
    // Cleanup the event listener when the component is destroyed
    window.removeEventListener("resize", this.checkIfMobile);
  },
};
</script>

<style scoped>
/* .sidebar {
  transition: transform 0.3s ease;
}
.sidebar-open {
  transform: translateX(0); 
}
.sidebar {
  transform: translateX(-100%); 
} */

/* .overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 1000;
} */

.sidebar {
  display: block; /* Visible on large screens */
}

/* Sidebar toggle behavior for mobile screens */
@media screen and (max-width: 568px) {
  .sidebar {
    display: none; /* Hidden by default on mobile */
    position: fixed;
    width: 250px;
    height: 100vh;
    top: 0;
    left: -250px; /* Initially hidden off-screen */
    background-color: #fff;
    z-index: 9999;
    transition: left 0.3s ease; /* Smooth slide transition */
  }

  .sidebar.open {
    display: block; /* Display sidebar on mobile when open */
    left: 0; /* Slide sidebar into view */
  }

  .overlay {
    display: block;
  }

  .small-mobile-menu {
    display: none;
  }
}
</style>
